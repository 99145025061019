import type {Institution, Account, Transaction, Item} from 'plaid';

import {createAsIsSerialization, Serialization} from '../util/serialization';

export type PlaidData = {
  logins: Item[];
  institutions: Institution[];
  accounts: {[loginID: string]: Account[]};
  transactions: Transaction[];
};

export const emptyPlaidData: PlaidData = {
  logins: [],
  institutions: [],
  accounts: {},
  transactions: [],
};

export const plaidDataSerialization: Serialization<PlaidData, PlaidData> =
  createAsIsSerialization<PlaidData>();
