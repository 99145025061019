import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import ReactDOM from 'react-dom';

import ModalContext from './ModalContext';

type Props = {
  onClose: () => void;
  children: ReactNode;
};

export default function Modal(props: Props): ReactElement {
  const modalContext = useContext(ModalContext);
  const elementRef = useRef<HTMLDivElement>(
    useMemo(() => document.createElement('div'), []),
  );
  useEffect(() => {
    modalContext.modalRoot.appendChild(elementRef.current);
    return () => {
      modalContext.modalRoot.removeChild(elementRef.current);
    };
  }, []);

  const {onClose, children} = props;

  return ReactDOM.createPortal(
    <div
      onClick={e => {
        // Closes on background click
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      aria-hidden="true"
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <button
        onClick={onClose}
        type="button"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '20px 20px 0 0',
          fontSize: 30,
        }}>
        ✕
      </button>
      <div
        style={{
          maxHeight: '90%',
          background: '#FFF',
          overflow: 'auto',
          padding: 10,
        }}>
        {children}
      </div>
    </div>,
    elementRef.current,
  );
}
