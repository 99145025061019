import invariant from 'invariant';

import type {ManualAccount} from '../data/manualAccount';
import type {PlaidAccount} from '../plaid';

export const accountTypeNames: {
  type: string;
  name: string;
  subTypeNames: {subType: string; name: string; negateBalance?: boolean}[];
}[] = [
  {
    type: 'depository',
    name: 'Deposit',
    subTypeNames: [
      {subType: 'checking', name: 'Checking'},
      {subType: 'savings', name: 'Savings'},
      {subType: 'cd', name: 'CD'},
    ],
  },
  {
    type: 'credit',
    name: 'Credit',
    subTypeNames: [
      {subType: 'credit card', name: 'Credit Cards', negateBalance: true},
    ],
  },
  {
    type: 'loan',
    name: 'Loans',
    subTypeNames: [
      {subType: 'mortgage', name: 'Mortgages', negateBalance: true},
    ],
  },
  {
    type: 'investment',
    name: 'Investments',
    subTypeNames: [
      {subType: 'brokerage', name: 'Brokerage'},
      {subType: '401k', name: '401k'},
      {subType: 'roth', name: 'Roth IRA'},
      {subType: 'stock plan', name: 'Stock Plan'},
      {subType: 'hsa', name: 'HSA'},
    ],
  },
  {
    type: 'equity',
    name: 'Equity',
    subTypeNames: [
      {subType: 'real_estate', name: 'Real Estate'},
      {subType: 'vested rsus', name: 'Vested RSUs'},
    ],
  },
];

export function getAccountTypeName(account: PlaidAccount | ManualAccount): {
  typeIdx: number;
  typeName: string;
  subTypeIdx: number;
  subTypeName: string;
  negateBalance: boolean;
} {
  const typeIdx = accountTypeNames.findIndex(
    accountTypeName => accountTypeName.type === account.type,
  );
  invariant(
    typeIdx !== -1,
    `Account (${account.account_id}) has an unknown type: ${account.type}`,
  );

  const subTypeIdx = accountTypeNames[typeIdx].subTypeNames.findIndex(
    subTypeName => subTypeName.subType === account.subtype,
  );
  invariant(
    subTypeIdx !== -1,
    `Account (${account.account_id}) has an unknown subtype: ${account.subtype} (type: ${account.type})`,
  );

  return {
    typeIdx,
    typeName: accountTypeNames[typeIdx].name,
    subTypeIdx,
    subTypeName: accountTypeNames[typeIdx].subTypeNames[subTypeIdx].name,
    negateBalance:
      !!accountTypeNames[typeIdx].subTypeNames[subTypeIdx].negateBalance,
  };
}
