import React from 'react';

import {FixtureFile} from '../data/fixtureFile';

export type CurrentFixture = {
  fixtureFile: FixtureFile;
  version: number;
};

export type FixtureContextInitial = {
  status: 'initial';
};

export type FixtureContextLoading = {
  status: 'loading';
};

export type FixtureContextLoadingError = {
  status: 'loadingError';
  error: Error;
};

export type FixtureContextLoaded = {
  status: 'loaded';
  currentFixture: CurrentFixture;
  updateFixture(newFixture: FixtureFile): Promise<void>;
};

export type FixtureContextUpdating = {
  status: 'updating';
  currentFixture: CurrentFixture;
};

export type FixtureContextValue =
  | FixtureContextInitial
  | FixtureContextLoading
  | FixtureContextLoadingError
  | FixtureContextLoaded
  | FixtureContextUpdating;

export type FixtureContextStatus = FixtureContextValue['status'];

export const defaultFixtureContextValue: FixtureContextValue = {
  status: 'initial',
};

const FixtureContext = React.createContext<FixtureContextValue>(
  defaultFixtureContextValue,
);
FixtureContext.displayName = 'FixtureContext';

export default FixtureContext;
