import assertNever from 'assert-never';
import {pmt} from 'financial';

import {Day} from '../date';
import {daySerialization, SerializedDay} from '../date/Day';
import Month from '../date/Month';
import {Amount, amountSerialization, SerializedAmount} from '../util/amount';
import {Serialization} from '../util/serialization';

import {CashflowOverTime} from './cashflow';
import {
  Frequency,
  frequencySerialization,
  SerializedFrequency,
} from './frequency';

export type Loan = {
  loanAmount: Amount;
  interestRatePerPeriod: number;
  totalPeriods: number;
  frequency: Frequency;
  startingDay: Day;
};

export type SerializedLoan = {
  loanAmount: SerializedAmount;
  interestRatePerPeriod: number;
  totalPeriods: number;
  frequency: SerializedFrequency;
  startingDay: SerializedDay;
};

export const loanSerialization: Serialization<Loan, SerializedLoan> = {
  serialize: loan => {
    return {
      loanAmount: amountSerialization.serialize(loan.loanAmount),
      interestRatePerPeriod: loan.interestRatePerPeriod,
      totalPeriods: loan.totalPeriods,
      frequency: frequencySerialization.serialize(loan.frequency),
      startingDay: daySerialization.serialize(loan.startingDay),
    };
  },
  deserialize: serialized => {
    return {
      loanAmount: amountSerialization.deserialize(serialized.loanAmount),
      interestRatePerPeriod: serialized.interestRatePerPeriod,
      totalPeriods: serialized.totalPeriods,
      frequency: frequencySerialization.deserialize(serialized.frequency),
      startingDay: daySerialization.deserialize(serialized.startingDay),
    };
  },
};

export function getLoanAnnualRate(loan: Loan): number {
  if (loan.frequency.frequency === 'monthly') {
    return loan.interestRatePerPeriod * 12;
  }
  assertNever(loan.frequency.frequency);
}

export function getLoanEndingDay(loan: Loan): Day {
  if (loan.frequency.frequency === 'monthly') {
    const startMonth = Month.truncateDay(loan.startingDay);
    // The -1 is because we are doing inclusive
    const endMonth = startMonth.getNthSucceeding(loan.totalPeriods - 1);
    return new Day(endMonth.year, endMonth.month, loan.startingDay.day);
  }
  assertNever(loan.frequency.frequency);
}

export function getLoanPaymentAmount(loan: Loan): number {
  return pmt(
    loan.interestRatePerPeriod,
    loan.totalPeriods,
    loan.loanAmount.value,
  );
}

export function getLoanCashflowOverTime(loan: Loan): CashflowOverTime {
  return [
    {
      timePeriod: {
        starting: loan.startingDay,
        ending: getLoanEndingDay(loan),
      },
      amount: {
        type: 'exactAmount',
        amount: {
          value: getLoanPaymentAmount(loan),
          currency: loan.loanAmount.currency,
        },
      },
      frequency: loan.frequency,
    },
  ];
}
