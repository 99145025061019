import React, {ReactElement, ReactNode} from 'react';

import assertNever from 'assert-never';
import invariant from 'invariant';

import {
  isElement,
  RenderedCell,
  renderTableRows,
  TableConfig,
  TableRow,
} from '../rendering/table';

type Props = {
  rows: TableRow[];
  tableConfig: TableConfig;
};

function getKeyForRenderedRow(
  renderedRow: RenderedCell[],
  tableConfig: TableConfig,
): string {
  const keyColumnIdxes = tableConfig
    .map((columnConfig, idx) => (columnConfig.useAsKey ? idx : null))
    .filter((idx): idx is number => idx != null);
  invariant(keyColumnIdxes.length > 0, 'Table does not have any key columns');

  const keyCells = keyColumnIdxes.map(idx => renderedRow[idx]).filter(Boolean);
  return keyCells
    .map(keyCell => {
      if (typeof keyCell === 'string') {
        return keyCell;
      } else {
        invariant(!isElement(keyCell), 'A key cell cannot be a react element');
        return keyCell.value;
      }
    })
    .join('-');
}

export default function Table(props: Props): ReactElement {
  const {rows, tableConfig} = props;
  const thead = (
    <thead>
      <tr>
        {tableConfig.map(columnConfig => {
          let cellAlign: 'left' | 'right' = 'left';
          if (columnConfig.align === 'left') {
            cellAlign = 'left';
          } else if (columnConfig.align === 'right') {
            cellAlign = 'right';
          } else {
            assertNever(columnConfig.align);
          }
          return (
            <td key={columnConfig.header} align={cellAlign}>
              {columnConfig.header}
            </td>
          );
        })}
      </tr>
    </thead>
  );

  const renderedRows: RenderedCell[][] = renderTableRows(
    rows,
    tableConfig,
    0,
    (indentLevel: number) => `${'--'.repeat(indentLevel)} `,
  );
  const tbody = (
    <tbody>
      {renderedRows.map(renderedRow => (
        <tr key={getKeyForRenderedRow(renderedRow, tableConfig)}>
          {renderedRow.map((renderedCell, idx) => {
            const columnConfig = tableConfig[idx];

            let cellValue: ReactNode;
            if (typeof renderedCell === 'string') {
              cellValue = renderedCell;
            } else if (isElement(renderedCell)) {
              cellValue = renderedCell.reactElement;
            } else {
              cellValue = renderedCell.value;
            }

            let cellAlign: 'left' | 'right' = 'left';
            if (columnConfig.align === 'left') {
              cellAlign = 'left';
            } else if (columnConfig.align === 'right') {
              cellAlign = 'right';
            } else {
              assertNever(columnConfig.align);
            }

            let cellColor;
            if (typeof renderedCell === 'object' && !isElement(renderedCell)) {
              if (renderedCell.color === 'dim') {
                cellColor = 'grey';
              } else if (renderedCell.color == null) {
                // do nothing
              } else {
                assertNever(renderedCell.color);
              }
            }
            return (
              <td
                align={cellAlign}
                style={{color: cellColor}}
                key={columnConfig.header}>
                {cellValue}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
  return (
    <table cellSpacing={8}>
      {thead}
      {tbody}
    </table>
  );
}
