import {
  DataMapping,
  dataMappingSerialization,
  emptyDataMapping,
  SerializedDataMapping,
} from '../dataMapping/dataMapping';
import {
  convertToNullArraySerialization,
  createAsIsSerialization,
  Serialization,
} from '../util/serialization';

import {ManualAccount} from './manualAccount';

export type ManualData = {
  accounts: ManualAccount[];
  dataMapping: DataMapping;
};

export const emptyManualData: ManualData = {
  accounts: [],
  dataMapping: emptyDataMapping,
};

export type SerializedManualData = {
  accounts?: ManualAccount[];
  dataMapping?: SerializedDataMapping;
};

const manualAccountListSerialization: Serialization<
  ManualAccount[],
  ManualAccount[] | null
> = convertToNullArraySerialization(createAsIsSerialization<ManualAccount[]>());

export const manualDataSerialization: Serialization<
  ManualData,
  SerializedManualData | null
> = {
  serialize: manualData => {
    let serialized: SerializedManualData | null = null;

    const serializedAccounts = manualAccountListSerialization.serialize(
      manualData.accounts,
    );
    if (serializedAccounts) {
      serialized = serialized || {};
      serialized.accounts = serializedAccounts;
    }

    const serializedDataMapping = dataMappingSerialization.serialize(
      manualData.dataMapping,
    );
    if (serializedDataMapping) {
      serialized = serialized || {};
      serialized.dataMapping = serializedDataMapping;
    }

    return serialized;
  },
  deserialize: serialized => {
    if (!serialized) {
      return emptyManualData;
    }
    return {
      accounts: manualAccountListSerialization.deserialize(
        serialized.accounts || null,
      ),
      dataMapping: dataMappingSerialization.deserialize(
        serialized.dataMapping || null,
      ),
    };
  },
};
