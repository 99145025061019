import type {ReactElement} from 'react';

import assertNever from 'assert-never';
import invariant from 'invariant';

import {Amount} from '../util/amount';

import {renderAmount} from './formatting';

type TableColumnConfig = {
  header: string;
  align: 'left' | 'right';
  maxLength: number | null;
  indent: boolean;
  useAsKey: boolean;
};

const defaultTableColumnConfig: TableColumnConfig = {
  header: '',
  align: 'left',
  maxLength: null,
  indent: false,
  useAsKey: false,
};

class Sum {}
const sumSigil = new Sum();

class ReactElementWrapper {
  constructor(public reactElement: ReactElement) {}
}

type CellValue = string | number | Amount | null;

export type TableCell = CellValue | Sum | ReactElementWrapper;

export function sum(): Sum {
  return sumSigil;
}

export function element(reactElement: ReactElement): ReactElementWrapper {
  return new ReactElementWrapper(reactElement);
}

export function isElement(obj: unknown): obj is ReactElementWrapper {
  return obj instanceof ReactElementWrapper;
}

export function header(str: string): TableColumnConfig {
  return {
    ...defaultTableColumnConfig,
    header: str,
  };
}

export function right(config: TableColumnConfig): TableColumnConfig {
  return {
    ...config,
    align: 'right',
  };
}

export function cap(
  config: TableColumnConfig,
  maxLength: number,
): TableColumnConfig {
  return {
    ...config,
    maxLength,
  };
}

export function indent(config: TableColumnConfig): TableColumnConfig {
  return {
    ...config,
    indent: true,
  };
}

export function useAsKey(config: TableColumnConfig): TableColumnConfig {
  return {
    ...config,
    useAsKey: true,
  };
}

type ChildrenRows = {
  children: TableRow[];
};

type SimpleTableRow = TableCell[];

export type TableRow = SimpleTableRow | ChildrenRows;

export function children(rows: TableRow[]): TableRow {
  return {
    children: rows,
  };
}

export type TableConfig = TableColumnConfig[];

type RowSums = (number | Amount)[];

function addRowSums(
  tableConfig: TableConfig,
  rowSumsArray: RowSums[],
): RowSums {
  return tableConfig.map((_, idx) => {
    return rowSumsArray
      .map(rowSums => rowSums[idx] ?? 0)
      .reduce((acc, current) => {
        if (typeof acc === 'number') {
          if (typeof current === 'number') {
            return acc + current;
          } else {
            return {
              value: current.value + acc,
              currency: current.currency,
            };
          }
        } else if (typeof current === 'number') {
          return {
            value: acc.value + current,
            currency: acc.currency,
          };
        } else {
          invariant(
            acc.currency === current.currency,
            'Currencies do not match',
          );
          return {
            value: acc.value + current.value,
            currency: acc.currency,
          };
        }
      }, 0);
  });
}

function getRowSums(
  row: TableRow,
  tableConfig: TableConfig,
): (number | Amount)[] {
  if (Array.isArray(row)) {
    return row.map(cell => {
      if (cell === null) {
        return 0;
      } else if (typeof cell === 'string') {
        return 0;
      } else if (typeof cell === 'number') {
        return cell;
      } else if (cell instanceof Sum) {
        return 0;
      } else if (isElement(cell)) {
        return 0;
      } else if (typeof cell === 'object') {
        return cell;
      } else {
        assertNever(cell);
      }
    });
  } else {
    return addRowSums(
      tableConfig,
      row.children.map(c => getRowSums(c, tableConfig)),
    );
  }
}

function renderCell(cell: CellValue): string {
  if (cell == null) {
    return '';
  } else if (typeof cell === 'string') {
    return cell;
  } else if (typeof cell === 'number') {
    return cell.toString();
  } else if (typeof cell === 'object') {
    return renderAmount(cell.currency, cell.value);
  } else {
    assertNever(cell);
  }
}

export type RenderedCellColor = null | 'dim';
export type RenderedCell =
  | string
  | ReactElementWrapper
  | {
      value: string;
      color: RenderedCellColor;
    };

function renderSimpleTableRow(
  row: SimpleTableRow,
  tableConfig: TableConfig,
  rowSums: RowSums,
  indentLevel: number,
  indentPrefix: (indentLevel: number) => string,
): RenderedCell[] {
  return row.map((cell, idx): RenderedCell => {
    if (isElement(cell)) {
      return cell;
    }

    const columnConfig = tableConfig[idx];

    let renderedValue: string;
    let color: RenderedCellColor = null;
    if (cell instanceof Sum) {
      renderedValue = renderCell(rowSums[idx]);
      color = 'dim';
    } else {
      renderedValue = renderCell(cell);
    }

    if (columnConfig.indent) {
      renderedValue = indentPrefix(indentLevel) + renderedValue;
    }

    if (typeof columnConfig.maxLength === 'number') {
      renderedValue =
        renderedValue.length <= columnConfig.maxLength
          ? renderedValue
          : `${renderedValue.slice(0, columnConfig.maxLength - 1).trimEnd()}…`;
    }

    if (color) {
      return {
        value: renderedValue,
        color,
      };
    } else {
      return renderedValue;
    }
  });
}

export function renderTableRows(
  rows: TableRow[],
  tableConfig: TableConfig,
  indentLevel: number,
  indentPrefix: (indentLevel: number) => string,
): RenderedCell[][] {
  const renderedRows: RenderedCell[][] = [];
  let runningSums: RowSums = tableConfig.map(_ => 0);
  rows.forEach(row => {
    // This is unnecessarily doing quadratic the work.
    const rowSums = getRowSums(row, tableConfig);
    runningSums = addRowSums(tableConfig, [runningSums, rowSums]);
    if (Array.isArray(row)) {
      renderedRows.push(
        renderSimpleTableRow(
          row,
          tableConfig,
          runningSums,
          indentLevel,
          indentPrefix,
        ),
      );
    } else {
      renderedRows.push(
        ...renderTableRows(
          row.children,
          tableConfig,
          indentLevel + 1,
          indentPrefix,
        ),
      );
    }
  });
  return renderedRows;
}
