import React from 'react';

export type ModalContextValue = {
  modalRoot: HTMLElement;
};

export const defaultFixtureContextValue: ModalContextValue = {
  modalRoot: document.createElement('div'),
};

const ModalContext = React.createContext<ModalContextValue>(
  defaultFixtureContextValue,
);
ModalContext.displayName = 'ModalContext';

export default ModalContext;
