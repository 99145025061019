import invariant from 'invariant';
import nullthrows from 'nullthrows';

import type {ManualData} from '../data/manualData';
import type {PlaidData} from '../plaid';

export function getAccountName(
  plaidData: PlaidData,
  manualData: ManualData | null | undefined,
  accountID: string,
): string {
  for (const loginID of Object.keys(plaidData.accounts)) {
    for (const account of plaidData.accounts[loginID]) {
      if (account.account_id === accountID) {
        const login = nullthrows(
          plaidData.logins.find(l => l.item_id === loginID),
        );
        const institution = nullthrows(
          plaidData.institutions.find(
            ins => ins.institution_id === login.institution_id,
          ),
        );
        return `${institution.name}: ${account.name}`;
      }
    }
  }

  for (const manualAccount of manualData?.accounts || []) {
    if (manualAccount.account_id === accountID) {
      return `Manual entry: ${manualAccount.name}`;
    }
  }

  invariant(false, `Account ${accountID} not found`);
}
