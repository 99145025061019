import React, {ReactElement, useContext} from 'react';

import invariant from 'invariant';

import {FixtureFile} from '../data/fixtureFile';
import {getCategoryByID, updateCategories} from '../dataMapping/categories';

import FixtureContext, {FixtureContextValue} from './FixtureContext';

type Props = {
  categoryID: string;
  onClose: () => void;
};

export default function CategoryDeleteModal(props: Props): ReactElement {
  const {categoryID, onClose} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {categories} = fixtureFile.manualData.dataMapping;

  const category = getCategoryByID(categories, categoryID);
  invariant(category, 'Category must exist');

  const onDeleteClick = () => {
    const newCategories = updateCategories(
      categories,
      (parentCategory, originalCategories) => {
        const updatedCategories = originalCategories.filter(
          oc => oc.id !== categoryID,
        );
        if (updatedCategories.length !== originalCategories.length) {
          return updatedCategories;
        } else {
          return originalCategories;
        }
      },
    );

    const newTransactionsMetadata =
      fixtureFile.manualData.dataMapping.transactionsMetadata.map(tm => {
        if (tm.overrideCategoryID === categoryID) {
          return {
            ...tm,
            overrideCategoryID: null,
          };
        } else {
          return tm;
        }
      });

    const newFixture: FixtureFile = {
      ...fixtureFile,
      manualData: {
        ...fixtureFile.manualData,
        dataMapping: {
          ...fixtureFile.manualData.dataMapping,
          transactionsMetadata: newTransactionsMetadata,
          categories: newCategories,
        },
      },
    };

    invariant(
      fixtureContext.status === 'loaded',
      'Fixture state must be loaded',
    );
    fixtureContext.updateFixture(newFixture).then(onClose);
  };

  return (
    <div>
      <p>
        Are you sure you want to delete the category &quot;{category.name}
        &quot; and all of its subcategories?
      </p>
      <p>
        All transactions tagged with this category and its subcategories will be
        uncategorized after.
      </p>
      <button
        type="button"
        onClick={onClose}
        disabled={fixtureContext.status !== 'loaded'}>
        Cancel
      </button>{' '}
      <button
        type="button"
        onClick={onDeleteClick}
        disabled={fixtureContext.status !== 'loaded'}>
        Permanently delete
      </button>
    </div>
  );
}
