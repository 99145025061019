import {FixtureFile} from '../data/fixtureFile';
import {Category} from '../dataMapping/categories';
import {getTransactionsForCategoryDuringPeriod} from '../dataMapping/getTransactions';
import {Day} from '../date';
import Month from '../date/Month';

import sumTransactionAmounts from './sumTransactionAmounts';
import {
  children,
  header,
  indent,
  right,
  sum,
  TableConfig,
  TableRow,
  useAsKey,
} from './table';

export default function getCashflowForTable(fixture: FixtureFile): {
  rows: TableRow[];
  tableConfig: TableConfig;
} {
  // This would def be wrong if multi currency
  const defaultCurrency = 'USD';

  const totalsRow = ['Total', sum(), sum(), sum(), sum(), sum()];

  const recurseCategory = (category: Category | null): TableRow[] => {
    const trailing30DaysAmount = sumTransactionAmounts(
      getTransactionsForCategoryDuringPeriod(
        fixture,
        category,
        // Trailing 30 days
        {
          starting: Day.today().getNthSucceeding(-30),
          ending: Day.today(),
        },
      ),
      defaultCurrency,
    );
    const trailing3MonthsAmount = sumTransactionAmounts(
      getTransactionsForCategoryDuringPeriod(
        fixture,
        category,
        // Trailing 3 months
        {
          starting: Month.currentMonth()
            .getNthSucceeding(-3)
            .getNthDayOfMonth(1),
          ending: Month.currentMonth().getNthDayOfMonth(1).getPreceeding(),
        },
      ),
      defaultCurrency,
    );
    const trailing12MonthsAmount = sumTransactionAmounts(
      getTransactionsForCategoryDuringPeriod(
        fixture,
        category,
        // Trailing 12 months
        {
          starting: Month.currentMonth()
            .getNthSucceeding(-13)
            .getNthDayOfMonth(1),
          ending: Month.currentMonth().getNthDayOfMonth(1).getPreceeding(),
        },
      ),
      defaultCurrency,
    );

    const trailing3MonthsRunRate = trailing3MonthsAmount
      ? {...trailing3MonthsAmount, value: trailing3MonthsAmount.value / 3}
      : null;
    const trailing12MonthsRunRate = trailing12MonthsAmount
      ? {...trailing12MonthsAmount, value: trailing12MonthsAmount.value / 12}
      : null;

    const childrenRows = (category?.subCategories || [])
      .map(recurseCategory)
      .flat(1);
    // Don't show total if there's just 1 sub category
    if (childrenRows.length > 1) {
      childrenRows.push([
        ...totalsRow,
        `${category ? category.id : 'uncategorized'}-total`,
      ]);
    }

    return [
      [
        category?.name ?? 'Uncategorized',
        trailing30DaysAmount,
        trailing3MonthsRunRate,
        trailing12MonthsRunRate,
        trailing3MonthsAmount,
        trailing12MonthsAmount,
        category ? category.id : 'uncategorized',
      ],
      ...(childrenRows.length > 0 ? [children(childrenRows)] : []),
    ];
  };

  return {
    rows: [
      ...recurseCategory(null),
      ...fixture.manualData.dataMapping.categories.map(recurseCategory).flat(1),
      [...totalsRow, 'all-categories-total'],
    ],
    tableConfig: [
      indent(header('Category')),
      right(header('Trailing 30 days')),
      right(header('3 months run rate')),
      right(header('12 months run rate')),
      right(header('Trailing 3 months')),
      right(header('Trailing 12 months')),
      useAsKey(header('Category ID')),
    ],
  };
}
