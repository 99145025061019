function depthFirstImpl<T>(
  objs: T[],
  getSubObjs: (t: T) => T[] | null | undefined,
  callback: (obj: T, ancestors: T[]) => void,
  ancestors: T[],
): void {
  objs.forEach(obj => {
    callback(obj, ancestors);

    const subObjs = getSubObjs(obj) || [];

    const newAncestors = ancestors.slice(0);
    newAncestors.push(obj);
    depthFirstImpl(subObjs, getSubObjs, callback, newAncestors);
  });
}

export function depthFirst<T>(
  objs: T[],
  getSubObjs: (t: T) => T[] | null | undefined,
  callback: (obj: T, ancestors: T[]) => void,
): void {
  depthFirstImpl(objs, getSubObjs, callback, []);
}
