import {createAsIsSerialization, Serialization} from './serialization';

export type Amount = {
  value: number;
  currency: string;
};

export type SerializedAmount = Amount;

export const amountSerialization: Serialization<Amount, SerializedAmount> =
  createAsIsSerialization();
