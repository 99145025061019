import assertNever from 'assert-never';
import invariant from 'invariant';

import {Compound, Negate} from './functional';

function matchesCompoundFilter<ValueType, Filter>(
  value: ValueType,
  compound: Compound<Filter>,
  matchesFilter: (s: ValueType, filter: Filter) => boolean,
): boolean {
  if (compound.compoundType === 'and') {
    return compound.compound.every(o => matchesFilter(value, o));
  } else if (compound.compoundType === 'or') {
    return compound.compound.some(o => matchesFilter(value, o));
  }
  assertNever(compound.compoundType);
}

/* String */

export type ExactStringFilter = {
  type: 'exact';
  value: string;
};

export type ContainsStringFilter = {
  type: 'contains';
  value: string;
};

export type PrimitiveStringFilter = ExactStringFilter | ContainsStringFilter;

export type StringFilter =
  | PrimitiveStringFilter
  | Negate<StringFilter>
  | Compound<StringFilter>;

export function matchesStringFilter(
  str: string,
  filter: StringFilter,
): boolean {
  switch (filter.type) {
    case 'exact':
      return str === filter.value;
    case 'contains':
      return str.includes(filter.value);
    case 'negate':
      return !matchesStringFilter(str, filter.negate);
    case 'compound':
      return matchesCompoundFilter(str, filter, matchesStringFilter);
    default:
      assertNever(filter);
  }
}

/* Number */

export type ExactNumberFilter = {
  type: 'exact';
  value: number;
};

export type LessThanNumberFilter = {
  type: 'lessThan';
  value: number;
};

export type GreaterThanNumberFilter = {
  type: 'greaterThan';
  value: number;
};

export type PrimitiveNumberFilter =
  | ExactNumberFilter
  | LessThanNumberFilter
  | GreaterThanNumberFilter;

export type NumberFilter =
  | PrimitiveNumberFilter
  | Negate<NumberFilter>
  | Compound<NumberFilter>;

export function matchesNumberFilter(
  num: number,
  filter: NumberFilter,
): boolean {
  switch (filter.type) {
    case 'exact':
      return num === filter.value;
    case 'lessThan':
      return num < filter.value;
    case 'greaterThan':
      return num > filter.value;
    case 'negate':
      return !matchesNumberFilter(num, filter.negate);
    case 'compound':
      return matchesCompoundFilter(num, filter, matchesNumberFilter);
    default:
      assertNever(filter);
  }
}

/* Objects */

export type PrimitiveObjectFilter<ObjType> = {
  type: 'properties';
  filters: {
    [Key in keyof ObjType]?: NonNullable<ObjType[Key]> extends string
      ? StringFilter
      : NonNullable<ObjType[Key]> extends number
      ? NumberFilter
      : never;
  };
};

export type ObjectFilter<ObjType> =
  | PrimitiveObjectFilter<ObjType>
  | Negate<PrimitiveObjectFilter<ObjType>>
  | Compound<ObjectFilter<ObjType>>;

export function matchesObjectFilter<ObjType>(
  obj: ObjType,
  filter: ObjectFilter<ObjType>,
): boolean {
  switch (filter.type) {
    case 'properties':
      // `every` by definition
      return Object.keys(filter.filters).every(propKey => {
        const key = propKey as keyof ObjType;
        const propValue = obj[key];
        if (propValue == null) {
          return false;
        } else if (typeof propValue === 'string') {
          return matchesStringFilter(
            propValue,
            filter.filters[key] as StringFilter,
          );
        } else if (typeof propValue === 'number') {
          return matchesNumberFilter(
            propValue,
            filter.filters[key] as NumberFilter,
          );
        } else {
          invariant(false, 'propValue is not a string or a number');
        }
      });
    case 'negate':
      return !matchesObjectFilter(obj, filter.negate);
    case 'compound':
      return matchesCompoundFilter(obj, filter, matchesObjectFilter);
    default:
      assertNever(filter);
  }
}
