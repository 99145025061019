import fetch from 'isomorphic-fetch';

import {
  FixtureFile,
  fixtureFileSerialization,
  SerializedFixtureFile,
} from '../data/fixtureFile';

import {
  FixtureFileSystem,
  FIXTURE_VERSION_FILENAME,
  getFixtureFilename,
} from './common';
import S3PermissionDeniedError from './S3PermissionDeniedError';

const S3_PUT_PERMISSIONS_HEADERS = {
  'x-amz-acl': 'bucket-owner-full-control',
};

export default class S3FixtureFileSystem implements FixtureFileSystem {
  constructor(private s3Dir: string) {}

  private getFixtureVersionUrl(): string {
    return `${this.s3Dir}/${FIXTURE_VERSION_FILENAME}`;
  }

  private getFixtureUrl(version: number): string {
    return `${this.s3Dir}/${getFixtureFilename(version)}`;
  }

  async hasFixtureForVersion(version: number): Promise<boolean> {
    const fixtureUrl = this.getFixtureUrl(version);
    const response = await fetch(fixtureUrl, {
      method: 'HEAD',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    return response.status === 200;
  }

  async getCurrentFixtureVersion(): Promise<number> {
    const response = await fetch(this.getFixtureVersionUrl(), {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    if (response.status !== 200) {
      throw new S3PermissionDeniedError();
    }
    let parsedResponse;
    try {
      parsedResponse = await response.json();
    } catch (error) {
      throw new Error('Response is not json');
    }
    if (typeof parsedResponse !== 'object') {
      throw new Error('Version file not an object');
    }
    if (
      !Object.prototype.hasOwnProperty.call(parsedResponse, 'fixtureVersion')
    ) {
      throw new Error('Object does not have fixtureVersion field');
    }
    const {fixtureVersion} = parsedResponse;
    if (typeof fixtureVersion !== 'number') {
      throw new Error('fixtureVersion not a number');
    }
    return fixtureVersion;
  }

  async getCurrentFixture(): Promise<{
    fixtureFile: FixtureFile;
    version: number;
  }> {
    const version = await this.getCurrentFixtureVersion();
    const fixtureFileText = await this.getFixtureFileAsText(version);
    const parsedFixtureFile: unknown = JSON.parse(fixtureFileText);
    // At some point, validate the serialization. But maybe that doesn't matter if we'll store in a db somewhere later.
    const fixtureFile = fixtureFileSerialization.deserialize(
      parsedFixtureFile as SerializedFixtureFile,
    );
    return {
      fixtureFile,
      version,
    };
  }

  async getFixtureFileAsText(version: number): Promise<string> {
    const fixtureUrl = this.getFixtureUrl(version);
    const response = await fetch(fixtureUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    if (response.status !== 200) {
      throw new S3PermissionDeniedError();
    }
    return response.text();
  }

  async writeFixtureFileAsText(
    fixtureFile: string,
    version: number,
  ): Promise<void> {
    const fixtureUrl = this.getFixtureUrl(version);
    const response = await fetch(fixtureUrl, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json',
        ...S3_PUT_PERMISSIONS_HEADERS,
      },
      body: fixtureFile,
    });
    if (response.status !== 200) {
      throw new S3PermissionDeniedError();
    }
  }

  async setVersion(version: number): Promise<void> {
    const fixtureVersionFile = JSON.stringify(
      {
        fixtureVersion: version,
      },
      null,
      '  ',
    );
    const response = await fetch(this.getFixtureVersionUrl(), {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json',
        ...S3_PUT_PERMISSIONS_HEADERS,
      },
      body: fixtureVersionFile,
    });
    if (response.status !== 200) {
      throw new S3PermissionDeniedError();
    }
  }

  async updateFixtureFile(
    fixtureFile: FixtureFile,
    version: number,
  ): Promise<number> {
    // There's a race condition in this implementation. There's no way to not
    // overwrite files on S3 upload; if that was a thing, there wouldn't be a
    // race condition here.

    const currentVersion = await this.getCurrentFixtureVersion();
    if (currentVersion !== version) {
      throw new Error(
        `Current version is no longer ${version} but is now ${currentVersion}.`,
      );
    }
    const serialized = JSON.stringify(
      fixtureFileSerialization.serialize(fixtureFile),
      null,
      '  ',
    );
    const nextVersion = currentVersion + 1;
    await this.writeFixtureFileAsText(serialized, nextVersion);

    await this.setVersion(nextVersion);

    return nextVersion;
  }
}
