import invariant from 'invariant';

import {TransactionFilter} from '../dataMapping/transactionFilter';
import {StringFilter} from '../util/filter';

export type SimpleTransactionFilter = {
  merchants: string[];
};

const defaultSimpleTransactionFilter: SimpleTransactionFilter = {
  merchants: [],
};

function simplifyStringFilter(stringFilter: StringFilter): string[] | null {
  if (stringFilter.type === 'contains') {
    return [stringFilter.value];
  } else if (
    stringFilter.type === 'compound' &&
    stringFilter.compoundType === 'or'
  ) {
    const simplifiedStringFilters =
      stringFilter.compound.map(simplifyStringFilter);
    const nonNullSimplifiedStringFilters = simplifiedStringFilters.filter(
      (s): s is string[] => s != null,
    );
    if (
      nonNullSimplifiedStringFilters.length !== simplifiedStringFilters.length
    ) {
      return null;
    }

    return nonNullSimplifiedStringFilters.flat(1);
  } else {
    return null;
  }
}

export function simplifyTransactionFilter(
  transactionFilter: TransactionFilter | null,
): SimpleTransactionFilter | null {
  if (transactionFilter == null) {
    return defaultSimpleTransactionFilter;
  }

  if (transactionFilter.type !== 'properties') {
    return null;
  }

  const filterKeys = Object.keys(transactionFilter.filters);
  if (filterKeys.length === 0) {
    return defaultSimpleTransactionFilter;
  } else if (filterKeys.length > 1) {
    return null;
  } else if (filterKeys[0] !== 'name') {
    return null;
  }

  const nameFilter = transactionFilter.filters.name;
  invariant(nameFilter, 'flow');
  const simplifiedStringFilter = simplifyStringFilter(nameFilter);
  if (!simplifiedStringFilter) {
    return null;
  }
  return {
    merchants: simplifiedStringFilter,
  };
}

export function unsimplifyTransactionFilter(
  simplifiedTransactionFilter: SimpleTransactionFilter,
): TransactionFilter | null {
  let stringFilter: StringFilter;
  if (simplifiedTransactionFilter.merchants.length === 0) {
    return null;
  } else if (simplifiedTransactionFilter.merchants.length === 1) {
    stringFilter = {
      type: 'contains',
      value: simplifiedTransactionFilter.merchants[0],
    };
  } else {
    stringFilter = {
      type: 'compound',
      compoundType: 'or',
      compound: simplifiedTransactionFilter.merchants.map(merchant => ({
        type: 'contains',
        value: merchant,
      })),
    };
  }
  return {
    type: 'properties',
    filters: {
      name: stringFilter,
    },
  };
}
