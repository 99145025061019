import React, {ReactElement} from 'react';

import invariant from 'invariant';

import {Category} from '../dataMapping/categories';
import {depthFirst} from '../util/traversal';

type Props = {
  categories: Category[];
  selectedCategoryID: string | null;
  onCategoryChange(categoryID: string | null): void;
  noCategoryName?: string;
};

const UNCATEGORIZED_OPTION_VALUE = '__uncategorized';

function ensureNotUncategorizedOptionValue(id: string): string {
  invariant(
    id !== UNCATEGORIZED_OPTION_VALUE,
    `Category id should not be ${UNCATEGORIZED_OPTION_VALUE}`,
  );
  return id;
}

export default function CategorySelector(props: Props): ReactElement {
  const {categories, selectedCategoryID, onCategoryChange, noCategoryName} =
    props;

  const optionsEl = [
    <option value={UNCATEGORIZED_OPTION_VALUE} key="uncategorized">
      {noCategoryName ?? 'Uncategorized'}
    </option>,
  ];
  depthFirst(
    categories,
    category => category.subCategories,
    (category, ancestors) => {
      optionsEl.push(
        <option
          value={ensureNotUncategorizedOptionValue(category.id)}
          key={category.id}>
          {'\u00A0'.repeat(ancestors.length * 3) + category.name}
        </option>,
      );
    },
  );

  return (
    <select
      onChange={e =>
        onCategoryChange(
          e.target.value === UNCATEGORIZED_OPTION_VALUE ? null : e.target.value,
        )
      }
      defaultValue={selectedCategoryID ?? undefined}>
      {optionsEl}
    </select>
  );
}
