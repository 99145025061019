import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import invariant from 'invariant';
import nullthrows from 'nullthrows';

import {FixtureFile} from '../data/fixtureFile';
import S3FixtureFileSystem from '../storage/S3FixtureFileSystem';

import FixtureContext, {
  CurrentFixture,
  defaultFixtureContextValue,
  FixtureContextInitial,
  FixtureContextLoading,
  FixtureContextLoadingError,
  FixtureContextUpdating,
  FixtureContextValue,
} from './FixtureContext';

const s3Fs = new S3FixtureFileSystem(
  process.env.NODE_ENV === 'production'
    ? nullthrows(process.env.S3_DIR_PROD)
    : nullthrows(process.env.S3_DIR_DEV),
);

type ContextState =
  | FixtureContextInitial
  | FixtureContextLoading
  | FixtureContextLoadingError
  | {
      // FixtureContextLoaded but without updateFixture
      // This is because updateFixture depends on currentFixture, so the resulting context value
      // is actually a computed value based on the contextState and updateFixture.
      status: 'loaded';
      currentFixture: CurrentFixture;
    }
  | FixtureContextUpdating;

export default function FixtureContextProvider(props: {
  children: ReactElement;
}): ReactElement {
  const {children} = props;

  const [context, setContext] = useState<ContextState>(
    defaultFixtureContextValue,
  );

  const updateFixture = useCallback(
    async (newFixture: FixtureFile) => {
      invariant(context.status === 'loaded', 'Can only update while loaded');

      setContext({status: 'updating', currentFixture: context.currentFixture});
      s3Fs
        .updateFixtureFile(newFixture, context.currentFixture.version)
        .then(newVersion =>
          setContext({
            status: 'loaded',
            currentFixture: {
              fixtureFile: newFixture,
              version: newVersion,
            },
          }),
        );
    },
    [context],
  );

  // Initialize it
  useEffect(() => {
    setContext({status: 'loading'});

    s3Fs
      .getCurrentFixture()
      .then(fixture =>
        setContext({
          status: 'loaded',
          currentFixture: fixture,
        }),
      )
      .catch(error =>
        setContext({
          status: 'loadingError',
          error,
        }),
      );
  }, []);

  const contextValue = useMemo<FixtureContextValue>((): FixtureContextValue => {
    if (context.status === 'loaded') {
      return {
        ...context,
        updateFixture,
      };
    } else {
      return context;
    }
  }, [context, updateFixture]);

  return (
    <FixtureContext.Provider value={contextValue}>
      {children}
    </FixtureContext.Provider>
  );
}
