export function renderAmount(
  currency: string | null,
  amount: number,
  negateBalance?: boolean,
): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  }).format((negateBalance ? -1 : 1) * amount);
}
