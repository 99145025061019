import type {PlaidAccount, PlaidInstitution, PlaidLogin} from '../plaid';

import {FixtureFile} from './fixtureFile';
import type {ManualAccount} from './manualAccount';

export function getAllAccounts(
  fixture: FixtureFile,
): (ManualAccount | PlaidAccount)[] {
  const {manualData, plaidData} = fixture;

  const plaidAccounts = Object.entries(plaidData.accounts)
    .map(([_loginID, accountsForLogin]) => accountsForLogin)
    .flat();

  const allAccounts: (PlaidAccount | ManualAccount)[] = [];
  allAccounts.push(...plaidAccounts);
  allAccounts.push(...(manualData?.accounts || []));

  return allAccounts;
}

export function getAccounts(
  fixture: FixtureFile,
): (ManualAccount | PlaidAccount)[] {
  return getAllAccounts(fixture).filter(
    account => !fixture.config?.ignoreAccounts?.includes(account.account_id),
  );
}

export function getAccountByID(
  fixture: FixtureFile,
  accountID: string,
): ManualAccount | PlaidAccount | null {
  return getAccounts(fixture).filter(a => a.account_id === accountID)[0];
}

export function getLoginByAccountID(
  fixture: FixtureFile,
  accountID: string,
): PlaidLogin | null {
  for (const loginID in fixture.plaidData.accounts) {
    if (
      fixture.plaidData.accounts[loginID].some(a => a.account_id === accountID)
    ) {
      return fixture.plaidData.logins.filter(l => l.item_id === loginID)[0];
    }
  }
  return null;
}

export function getInstitutionByID(
  fixture: FixtureFile,
  institutionID: string,
): PlaidInstitution | null {
  return fixture.plaidData.institutions.filter(
    ins => ins.institution_id === institutionID,
  )[0];
}

export function getInstitutionByAccountID(
  fixture: FixtureFile,
  accountID: string,
): PlaidInstitution | null {
  const login = getLoginByAccountID(fixture, accountID);
  if (!login) {
    return null;
  }
  return getInstitutionByID(fixture, login.institution_id);
}
