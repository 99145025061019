import React, {ReactElement, useState, useContext} from 'react';

import invariant from 'invariant';

import {FixtureFile} from '../data/fixtureFile';
import {Category, updateCategories} from '../dataMapping/categories';
import {
  children,
  element,
  header,
  indent,
  TableRow,
  useAsKey,
} from '../rendering/table';

import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Table from './Table';

type Props = {
  onClose: () => void;
};

const tableConfig = [
  indent(header('Category')),
  header('Move up'),
  header('Move down'),
  header('Move out of parent'),
  header('Move into next sibling'),
  useAsKey(header('Category ID')),
];

function getTableRows(
  categories: Category[],
  createMoveUpButton: (
    category: Category,
    siblingCategories: Category[],
  ) => ReactElement,
  createMoveDownButton: (
    category: Category,
    siblingCategories: Category[],
  ) => ReactElement,
  createMoveOutButton: (
    category: Category,
    siblingCategories: Category[],
  ) => ReactElement,
  createMoveInButton: (
    category: Category,
    siblingCategories: Category[],
  ) => ReactElement,
): TableRow[] {
  const recurseCategory = (
    parentCategory: Category | null,
    category: Category,
  ): TableRow[] => {
    const childrenRows = (category?.subCategories || [])
      .map(c => recurseCategory(category, c))
      .flat(1);
    return [
      [
        category.name,
        element(
          createMoveUpButton(
            category,
            parentCategory ? parentCategory.subCategories : categories,
          ),
        ),
        element(
          createMoveDownButton(
            category,
            parentCategory ? parentCategory.subCategories : categories,
          ),
        ),
        element(
          createMoveOutButton(
            category,
            parentCategory ? parentCategory.subCategories : categories,
          ),
        ),
        element(
          createMoveInButton(
            category,
            parentCategory ? parentCategory.subCategories : categories,
          ),
        ),
        category.id,
      ],
      ...(childrenRows.length > 0 ? [children(childrenRows)] : []),
    ];
  };
  return categories.map(c => recurseCategory(null, c)).flat(1);
}

export default function CategoryReorderModal(props: Props): ReactElement {
  const {onClose} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {categories} = fixtureFile.manualData.dataMapping;

  const [newCategories, setNewCategories] = useState<Category[] | null>(null);

  const createMoveUpButton = (
    category: Category,
    siblingCategories: Category[],
  ) => {
    return (
      <button
        type="button"
        disabled={siblingCategories[0].id === category.id}
        onClick={() =>
          setNewCategories(
            updateCategories(
              newCategories ?? categories,
              (_, originalCategories) => {
                const idx = originalCategories.findIndex(
                  c => c.id === category.id,
                );
                if (idx === -1 || idx === 0) {
                  return originalCategories;
                } else {
                  const updatedCategories = originalCategories.slice(0);
                  const oldCategory = updatedCategories[idx];
                  updatedCategories.splice(idx, 1);
                  updatedCategories.splice(idx - 1, 0, oldCategory);
                  return updatedCategories;
                }
              },
            ),
          )
        }>
        ↑
      </button>
    );
  };

  const createMoveDownButton = (
    category: Category,
    siblingCategories: Category[],
  ) => {
    return (
      <button
        type="button"
        disabled={
          siblingCategories[siblingCategories.length - 1].id === category.id
        }
        onClick={() =>
          setNewCategories(
            updateCategories(
              newCategories ?? categories,
              (_, originalCategories) => {
                const idx = originalCategories.findIndex(
                  c => c.id === category.id,
                );
                if (idx === -1 || idx === originalCategories.length - 1) {
                  return originalCategories;
                } else {
                  const updatedCategories = originalCategories.slice(0);
                  const oldCategory = updatedCategories[idx];
                  updatedCategories.splice(idx, 1);
                  updatedCategories.splice(idx + 1, 0, oldCategory);
                  return updatedCategories;
                }
              },
            ),
          )
        }>
        ↓
      </button>
    );
  };

  const createMoveOutButton = (
    category: Category,
    siblingCategories: Category[],
  ) => {
    return (
      <button
        type="button"
        disabled={siblingCategories === (newCategories ?? categories)}
        onClick={() => {
          let parentCategoryID: string;
          let oldCategory: Category;
          setNewCategories(
            updateCategories(
              newCategories ?? categories,
              (pc, originalCategories) => {
                if (parentCategoryID == null || !oldCategory) {
                  const idx = originalCategories.findIndex(
                    c => c.id === category.id,
                  );
                  if (idx === -1) {
                    return originalCategories;
                  } else {
                    invariant(pc, 'Must have a parent category');
                    parentCategoryID = pc.id;
                    const updatedCategories = originalCategories.slice(0);
                    oldCategory = updatedCategories[idx];
                    updatedCategories.splice(idx, 1);
                    return updatedCategories;
                  }
                } else {
                  const idx = originalCategories.findIndex(
                    c => c.id === parentCategoryID,
                  );
                  if (idx === -1) {
                    return originalCategories;
                  } else {
                    const updatedCategories = originalCategories.slice(0);
                    updatedCategories.splice(idx, 0, oldCategory);
                    return updatedCategories;
                  }
                }
              },
            ),
          );
        }}>
        ←
      </button>
    );
  };

  const createMoveInButton = (
    category: Category,
    siblingCategories: Category[],
  ) => {
    return (
      <button
        type="button"
        disabled={
          siblingCategories[siblingCategories.length - 1].id === category.id
        }
        onClick={() => {
          const categoryIdx = siblingCategories.findIndex(
            c => c.id === category.id,
          );
          const nextSiblingID = siblingCategories[categoryIdx + 1].id;
          setNewCategories(
            updateCategories(
              newCategories ?? categories,
              (pc, originalCategories) => {
                if (pc?.id === nextSiblingID) {
                  const updatedCategories = originalCategories.slice(0);
                  updatedCategories.unshift(category);
                  return updatedCategories;
                } else {
                  const siblingIdx = originalCategories.findIndex(
                    c => c.id === nextSiblingID,
                  );
                  if (siblingIdx === -1) {
                    return originalCategories;
                  } else {
                    const updatedCategories = originalCategories.slice(0);
                    updatedCategories.splice(siblingIdx - 1, 1);
                    return updatedCategories;
                  }
                }
              },
            ),
          );
        }}>
        →
      </button>
    );
  };

  const onSave = () => {
    invariant(newCategories, 'Must have newCategories');
    const newFixture: FixtureFile = {
      ...fixtureFile,
      manualData: {
        ...fixtureFile.manualData,
        dataMapping: {
          ...fixtureFile.manualData.dataMapping,
          categories: newCategories,
        },
      },
    };

    invariant(
      fixtureContext.status === 'loaded',
      'Fixture state must be loaded',
    );
    fixtureContext.updateFixture(newFixture).then(onClose);
  };

  return (
    <div>
      <p>Reorder categories</p>
      <Table
        rows={getTableRows(
          newCategories ??
            currentFixture.fixtureFile.manualData.dataMapping.categories,
          createMoveUpButton,
          createMoveDownButton,
          createMoveOutButton,
          createMoveInButton,
        )}
        tableConfig={tableConfig}
      />
      <button
        type="button"
        onClick={onClose}
        disabled={fixtureContext.status !== 'loaded'}>
        Cancel
      </button>{' '}
      <button
        type="button"
        onClick={onSave}
        disabled={fixtureContext.status !== 'loaded' || !newCategories}>
        Save
      </button>
    </div>
  );
}
