import React, {ReactElement, useContext, useState} from 'react';

import invariant from 'invariant';

import {FixtureFile} from '../data/fixtureFile';
import {
  emptyTransactionMetadata,
  getMetadataForTransaction,
  TransactionMetadata,
} from '../dataMapping/transactionMetadata';
import type {PlaidTransaction} from '../plaid';

import FixtureContext, {FixtureContextValue} from './FixtureContext';

type FieldProps = {
  value: string | null;
  saveDisabled: boolean;
  onUpdate: (newValue: string | null) => Promise<void>;
};

function TransactionDescriptionField(props: FieldProps): ReactElement {
  const {value, onUpdate, saveDisabled} = props;
  const [tempValue, setTempValue] = useState<string | null>(null);

  if (tempValue == null) {
    if (value != null) {
      return (
        <>
          {value}{' '}
          <button type="button" onClick={() => setTempValue(value)}>
            Edit
          </button>
        </>
      );
    } else {
      return (
        <button type="button" onClick={() => setTempValue('')}>
          Describe
        </button>
      );
    }
  } else {
    return (
      <>
        <input
          type="text"
          value={tempValue}
          onChange={e => setTempValue(e.target.value)}
          style={{width: 100}}
        />{' '}
        <button type="button" onClick={() => setTempValue(null)}>
          Cancel
        </button>{' '}
        <button
          type="button"
          onClick={async () => {
            await onUpdate(tempValue === '' ? null : tempValue);
            setTempValue(null);
          }}
          disabled={saveDisabled}>
          Save
        </button>
      </>
    );
  }
}

type Props = {
  transaction: PlaidTransaction;
};

export default function TransactionDescription(props: Props): ReactElement {
  const {transaction} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {transactionsMetadata} = fixtureFile.manualData.dataMapping;

  const transactionMetadata = getMetadataForTransaction(
    transaction.transaction_id,
    transactionsMetadata,
  );

  const onDescriptionChange = async (newDescription: string | null) => {
    invariant(
      fixtureContext.status === 'loaded',
      'Fixture state must be loaded',
    );

    let newTransactionsMetadata = transactionsMetadata.slice(0);
    if (!transactionMetadata) {
      const newTransactionMetadata: TransactionMetadata = {
        transactionID: transaction.transaction_id,
        ...emptyTransactionMetadata,
        description: newDescription,
      };
      newTransactionsMetadata.push(newTransactionMetadata);
    } else {
      const newTransactionMetadata: TransactionMetadata = {
        ...transactionMetadata,
        description: newDescription,
      };
      newTransactionsMetadata = newTransactionsMetadata.map(tm =>
        tm.transactionID === transaction.transaction_id
          ? newTransactionMetadata
          : tm,
      );
    }

    const newFixture: FixtureFile = {
      ...fixtureFile,
      manualData: {
        ...fixtureFile.manualData,
        dataMapping: {
          ...fixtureFile.manualData.dataMapping,
          transactionsMetadata: newTransactionsMetadata,
        },
      },
    };
    await fixtureContext.updateFixture(newFixture);
  };

  return (
    <TransactionDescriptionField
      value={transactionMetadata && transactionMetadata.description}
      onUpdate={onDescriptionChange}
      saveDisabled={fixtureContext.status !== 'loaded'}
    />
  );
}
