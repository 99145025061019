import {Account, Institution, Transaction, Item} from 'plaid';

import {createAsIsSerialization, Serialization} from '../util/serialization';

export const accountSerialization: Serialization<Account, Account> =
  createAsIsSerialization<Account>();

export const institutionSerialization: Serialization<Institution, Institution> =
  createAsIsSerialization<Institution>();

export const transactionSerialization: Serialization<Transaction, Transaction> =
  createAsIsSerialization<Transaction>();

export const loginSerialization: Serialization<Item, Item> =
  createAsIsSerialization<Item>();
