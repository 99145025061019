import {PlaidTransaction} from '../plaid';
import {matchesObjectFilter, ObjectFilter} from '../util/filter';
import {createAsIsSerialization, Serialization} from '../util/serialization';

export type TransactionFilter = ObjectFilter<PlaidTransaction>;

export type SerializedTransactionFilter = TransactionFilter;

export const transactionFilterSerialization: Serialization<
  TransactionFilter,
  SerializedTransactionFilter
> = createAsIsSerialization();

export const transactionMatchFilter: (
  transaction: PlaidTransaction,
  filter: TransactionFilter,
) => boolean = matchesObjectFilter;
