import React, {ReactElement, useContext, useState} from 'react';

import invariant from 'invariant';

import {FixtureFile} from '../data/fixtureFile';
import {
  emptyTransactionMetadata,
  getMetadataForTransaction,
  TransactionMetadata,
} from '../dataMapping/transactionMetadata';
import type {PlaidTransaction} from '../plaid';

import CategoryEditorModal from './CategoryEditorModal';
import CategorySelector from './CategorySelector';
import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Modal from './Modal';

type Props = {
  transaction: PlaidTransaction;
};

export default function CategorizeTransactionButton(
  props: Props,
): ReactElement {
  const {transaction} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {categories} = fixtureFile.manualData.dataMapping;

  const [selectedCategoryID, setSelectedCategoryID] = useState<string | null>(
    null,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const addTransaction = () => {
    invariant(selectedCategoryID != null, 'Must have a selected category ID');
    invariant(
      fixtureContext.status === 'loaded',
      'Fixture state must be loaded',
    );

    const existingTransactionsMetadata =
      fixtureFile.manualData.dataMapping.transactionsMetadata;
    const existingTransactionMetadata = getMetadataForTransaction(
      transaction.transaction_id,
      existingTransactionsMetadata,
    );

    let newTransactionsMetadata = existingTransactionsMetadata.slice(0);
    if (!existingTransactionMetadata) {
      const newTransactionMetadata: TransactionMetadata = {
        transactionID: transaction.transaction_id,
        ...emptyTransactionMetadata,
        overrideCategoryID: selectedCategoryID,
      };
      newTransactionsMetadata.push(newTransactionMetadata);
    } else {
      const newTransactionMetadata: TransactionMetadata = {
        ...existingTransactionMetadata,
        overrideCategoryID: selectedCategoryID,
      };
      newTransactionsMetadata = newTransactionsMetadata.map(tm =>
        tm.transactionID === transaction.transaction_id
          ? newTransactionMetadata
          : tm,
      );
    }

    const newFixture: FixtureFile = {
      ...fixtureFile,
      manualData: {
        ...fixtureFile.manualData,
        dataMapping: {
          ...fixtureFile.manualData.dataMapping,
          transactionsMetadata: newTransactionsMetadata,
        },
      },
    };
    fixtureContext.updateFixture(newFixture);
  };

  const editCategory = () => {
    setShowModal(true);
  };

  return (
    <>
      <CategorySelector
        categories={categories}
        selectedCategoryID={selectedCategoryID}
        onCategoryChange={setSelectedCategoryID}
      />{' '}
      <button
        type="button"
        disabled={
          selectedCategoryID == null || fixtureContext.status === 'updating'
        }
        onClick={addTransaction}>
        Add just this
      </button>{' '}
      <button
        type="button"
        disabled={
          selectedCategoryID == null || fixtureContext.status === 'updating'
        }
        onClick={editCategory}>
        Edit category
      </button>
      {showModal && selectedCategoryID != null ? (
        <Modal onClose={() => setShowModal(false)}>
          <CategoryEditorModal
            categoryID={selectedCategoryID}
            onClose={() => setShowModal(false)}
            prefill={{
              prependMerchantName: transaction.name || '',
            }}
          />
        </Modal>
      ) : null}
    </>
  );
}
