import React, {ReactElement, useContext, useState} from 'react';

import assertNever from 'assert-never';

import Balances from './Balances';
import Cashflow from './Cashflow';
import Categories from './Categories';
import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Transactions from './Transactions';

type Screen = 'balances' | 'transactions' | 'cashflow' | 'categories';
const screens: Screen[] = [
  'balances',
  'transactions',
  'cashflow',
  'categories',
];

function LoadingPage(): ReactElement {
  return <div>Loading fixture...</div>;
}

function LoadingErrorPage(props: {error: Error}): ReactElement {
  const {error} = props;
  return (
    <div>
      Error loading fixture:
      <br />
      {error.message}
    </div>
  );
}

function Header(props: {
  currentScreen: Screen;
  setCurrentScreen: (screen: Screen) => void;
}): ReactElement {
  const {currentScreen, setCurrentScreen} = props;
  return (
    <>
      <h1>WTFin</h1>
      <p>
        {screens.map((screen, idx) => {
          let text = null;
          if (currentScreen === screen) {
            text = screen;
          } else {
            text = (
              <a href={`#${screen}`} onClick={() => setCurrentScreen(screen)}>
                {screen}
              </a>
            );
          }
          if (idx !== screens.length - 1) {
            text = <>{text} | </>;
          }
          return <span key={screen}>{text}</span>;
        })}
      </p>
    </>
  );
}

function LoadedPage(): ReactElement {
  const [currentScreen, setCurrentScreen] = useState<Screen>('balances');

  let screenEl = null;
  if (currentScreen === 'balances') {
    screenEl = <Balances />;
  } else if (currentScreen === 'transactions') {
    screenEl = <Transactions />;
  } else if (currentScreen === 'cashflow') {
    screenEl = <Cashflow />;
  } else if (currentScreen === 'categories') {
    screenEl = <Categories />;
  } else {
    assertNever(currentScreen);
  }

  return (
    <div>
      <Header
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
      />
      {screenEl}
    </div>
  );
}

export function Page(): ReactElement {
  const fixtureContext: FixtureContextValue =
    useContext<FixtureContextValue>(FixtureContext);
  if (
    fixtureContext.status === 'initial' ||
    fixtureContext.status === 'loading'
  ) {
    return <LoadingPage />;
  } else if (fixtureContext.status === 'loadingError') {
    return <LoadingErrorPage error={fixtureContext.error} />;
  } else {
    return <LoadedPage />;
  }
}
