import 'regenerator-runtime/runtime';

import React from 'react';

import nullthrows from 'nullthrows';
import ReactDOM from 'react-dom';

import {App} from './App';

ReactDOM.render(
  <App modalRoot={nullthrows(document.getElementById('modalRoot'))} />,
  document.getElementById('root'),
);
