import type {Account, Institution, Transaction, Item} from 'plaid';

import {Serialization} from '../util/serialization';

import {
  createEmptyDiff,
  Diff,
  SerializedDiff,
  createDiffSerialization,
} from './diff';
import {
  accountSerialization,
  institutionSerialization,
  loginSerialization,
  transactionSerialization,
} from './plaidSerialization';

/* Plaid obj Diff serializations */

export const accountDiffSerialization: Serialization<
  Diff<Account>,
  SerializedDiff<Account> | null
> = createDiffSerialization(accountSerialization);

export const loginDiffSerialization: Serialization<
  Diff<Item>,
  SerializedDiff<Item> | null
> = createDiffSerialization(loginSerialization);

export const institutionDiffSerialization: Serialization<
  Diff<Institution>,
  SerializedDiff<Institution> | null
> = createDiffSerialization(institutionSerialization);

export const transactionDiffSerialization: Serialization<
  Diff<Transaction>,
  SerializedDiff<Transaction> | null
> = createDiffSerialization(transactionSerialization);

/* LoginAccountsDiff */

export type LoginAccountsDiff = {[loginID: string]: Diff<Account>};

type SerializedLoginAccountsDiff = {[loginID: string]: SerializedDiff<Account>};

export const loginAccountsDiffSerialization: Serialization<
  LoginAccountsDiff,
  SerializedLoginAccountsDiff | null
> = {
  serialize: loginAccountsDiff => {
    let serialized: SerializedLoginAccountsDiff | null = null;
    Object.keys(loginAccountsDiff).forEach(loginID => {
      const serializedAccountDiff = accountDiffSerialization.serialize(
        loginAccountsDiff[loginID],
      );
      if (serializedAccountDiff) {
        serialized = serialized || {};
        serialized[loginID] = serializedAccountDiff;
      }
    });
    return serialized;
  },
  deserialize: serialized => {
    if (!serialized) {
      return {};
    }

    return Object.fromEntries(
      Object.entries(serialized).map(([loginID, accountDiff]) => {
        return [loginID, accountDiffSerialization.deserialize(accountDiff)];
      }),
    );
  },
};

/* MergeLog */

export type MergeLog = {
  logins: Diff<Item>;
  institutions: Diff<Institution>;
  accounts: LoginAccountsDiff;
  transactions: Diff<Transaction>;
};

export type SerializedMergeLog = {
  logins?: SerializedDiff<Item>;
  institutions?: SerializedDiff<Institution>;
  accounts?: SerializedLoginAccountsDiff;
  transactions?: SerializedDiff<Transaction>;
};

export const mergeLogSerialization: Serialization<
  MergeLog,
  SerializedMergeLog | null
> = {
  serialize: mergeLog => {
    let serialized: SerializedMergeLog | null = null;

    const serializedLoginDiff = loginDiffSerialization.serialize(
      mergeLog.logins,
    );
    if (serializedLoginDiff) {
      serialized = serialized || {};
      serialized.logins = serializedLoginDiff;
    }

    const serializedInstitutionDiff = institutionDiffSerialization.serialize(
      mergeLog.institutions,
    );
    if (serializedInstitutionDiff) {
      serialized = serialized || {};
      serialized.institutions = serializedInstitutionDiff;
    }

    const serializedAccountDiff = loginAccountsDiffSerialization.serialize(
      mergeLog.accounts,
    );
    if (serializedAccountDiff) {
      serialized = serialized || {};
      serialized.accounts = serializedAccountDiff;
    }

    const serializedTransactionDiff = transactionDiffSerialization.serialize(
      mergeLog.transactions,
    );
    if (serializedTransactionDiff) {
      serialized = serialized || {};
      serialized.transactions = serializedTransactionDiff;
    }

    return serialized;
  },
  deserialize: serialized => {
    if (!serialized) {
      return {
        logins: createEmptyDiff(),
        institutions: createEmptyDiff(),
        accounts: {},
        transactions: createEmptyDiff(),
      };
    }
    return {
      logins: loginDiffSerialization.deserialize(serialized.logins || null),
      institutions: institutionDiffSerialization.deserialize(
        serialized.institutions || null,
      ),
      accounts: loginAccountsDiffSerialization.deserialize(
        serialized.accounts || null,
      ),
      transactions: transactionDiffSerialization.deserialize(
        serialized.transactions || null,
      ),
    };
  },
};
