import {FixtureFile} from '../data/fixtureFile';
import {Day} from '../date';
import {TimePeriod} from '../modeling/cashflow';
import {PlaidTransaction} from '../plaid';
import {depthFirst} from '../util/traversal';

import {
  Category,
  transactionMatchesAnyCategory,
  transactionMatchesCategory,
} from './categories';
import {getMetadataForTransaction} from './transactionMetadata';

export function getTransactionsForCategory(
  fixture: FixtureFile,
  category: Category | null,
): PlaidTransaction[] {
  const allCategories: Category[] = [];
  depthFirst(
    fixture.manualData.dataMapping.categories,
    c => c.subCategories,
    c => allCategories.push(c),
  );
  const supercedingCategories = category
    ? allCategories.slice(0, allCategories.indexOf(category))
    : allCategories;

  const txns = fixture.plaidData.transactions
    .filter(txn => !fixture.config.ignoreAccounts.includes(txn.account_id))
    .filter(txn => !txn.pending) // This isn't right, but hides the problem for now.
    .filter(txn => {
      const txnMetadata = getMetadataForTransaction(
        txn.transaction_id,
        fixture.manualData.dataMapping.transactionsMetadata,
      );
      return category
        ? transactionMatchesCategory(
            txn,
            txnMetadata,
            category,
            supercedingCategories,
          )
        : !transactionMatchesAnyCategory(txn, txnMetadata, allCategories);
    });

  return txns;
}

export function getTransactionsForCategoryDuringPeriod(
  fixture: FixtureFile,
  category: Category | null,
  timePeriod: TimePeriod,
): PlaidTransaction[] {
  const txns = getTransactionsForCategory(fixture, category);
  return txns.filter(txn => {
    const day = Day.deserialize(txn.date);
    return (
      timePeriod.starting.compare(day) <= 0 &&
      (!timePeriod.ending || day.compare(timePeriod.ending) <= 0)
    );
  });
}
