// Import from files directory so we don't pull in plaid runtime
import {
  mergeLogSerialization,
  SerializedMergeLog,
  MergeLog,
} from '../plaid/mergeLog';
import {
  emptyPlaidData,
  plaidDataSerialization,
  PlaidData,
} from '../plaid/plaidData';
import {
  convertToNullArraySerialization,
  createListSerialization,
  Serialization,
} from '../util/serialization';

import {
  Config,
  configSerialization,
  emptyConfig,
  SerializedConfig,
} from './config';
import {
  emptyManualData,
  ManualData,
  manualDataSerialization,
  SerializedManualData,
} from './manualData';

/* LogWithDate */

type LogWithDate = {
  date: Date;
  diff: MergeLog | null;
};

type SerializedLogWithDate = {
  date: string;
  diff?: SerializedMergeLog;
};

export const logWithDateSerialization: Serialization<
  LogWithDate,
  SerializedLogWithDate
> = {
  serialize: logWithDate => {
    const serialized: SerializedLogWithDate = {
      date: logWithDate.date.toJSON(),
    };

    const serializedDiff = logWithDate.diff
      ? mergeLogSerialization.serialize(logWithDate.diff)
      : null;
    if (serializedDiff) {
      serialized.diff = serializedDiff;
    }

    return serialized;
  },
  deserialize: serialized => {
    return {
      date: new Date(serialized.date),
      diff: mergeLogSerialization.deserialize(serialized.diff || null),
    };
  },
};

/* FixtureFile */

export type FixtureFile = {
  config: Config;
  plaidData: PlaidData;
  manualData: ManualData;
  logs: LogWithDate[];
};

export const emptyFixtureFile: FixtureFile = {
  config: emptyConfig,
  plaidData: emptyPlaidData,
  manualData: emptyManualData,
  logs: [],
};

export type SerializedFixtureFile = {
  config?: SerializedConfig;
  plaidData?: PlaidData;
  manualData?: SerializedManualData;
  logs?: SerializedLogWithDate[];
};

const logWithDateListSerialization: Serialization<
  LogWithDate[],
  SerializedLogWithDate[] | null
> = convertToNullArraySerialization(
  createListSerialization(logWithDateSerialization),
);

export const fixtureFileSerialization: Serialization<
  FixtureFile,
  SerializedFixtureFile
> = {
  serialize: fixtureFile => {
    const serialized: SerializedFixtureFile = {};

    const serializedConfig = configSerialization.serialize(fixtureFile.config);
    if (serializedConfig) {
      serialized.config = serializedConfig;
    }

    const serializedPlaidData = plaidDataSerialization.serialize(
      fixtureFile.plaidData,
    );
    if (serializedPlaidData) {
      serialized.plaidData = serializedPlaidData;
    }

    const serializedManualData = manualDataSerialization.serialize(
      fixtureFile.manualData,
    );
    if (serializedManualData) {
      serialized.manualData = serializedManualData;
    }

    const serializedLogs = logWithDateListSerialization.serialize(
      fixtureFile.logs,
    );
    if (serializedLogs) {
      serialized.logs = serializedLogs;
    }
    return serialized;
  },
  deserialize: serialized => {
    return {
      config: configSerialization.deserialize(serialized.config || null),
      plaidData: serialized.plaidData
        ? plaidDataSerialization.deserialize(serialized.plaidData)
        : emptyPlaidData,
      manualData: manualDataSerialization.deserialize(
        serialized.manualData || null,
      ),
      logs: logWithDateListSerialization.deserialize(serialized.logs || []),
    };
  },
};
