import {
  Serialization,
  stringNullListSerialization,
} from '../util/serialization';

export type Config = {
  ignoreAccounts: string[];
};

export const emptyConfig: Config = {
  ignoreAccounts: [],
};

export type SerializedConfig = {
  ignoreAccounts?: string[];
};

export const configSerialization: Serialization<
  Config,
  SerializedConfig | null
> = {
  serialize: config => {
    let serialized: SerializedConfig | null = null;

    const serializedIgnoreAccounts = stringNullListSerialization.serialize(
      config.ignoreAccounts,
    );
    if (serializedIgnoreAccounts) {
      serialized = serialized || {};
      serialized.ignoreAccounts = serializedIgnoreAccounts;
    }

    return serialized;
  },
  deserialize: serialized => {
    if (!serialized) {
      return emptyConfig;
    }
    return {
      ignoreAccounts: stringNullListSerialization.deserialize(
        serialized.ignoreAccounts || null,
      ),
    };
  },
};
