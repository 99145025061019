import {Serialization} from '../util/serialization';

export type TransactionMetadata = {
  transactionID: string;
  overrideCategoryID: string | null;
  description: string | null;
};

export const emptyTransactionMetadata: Omit<
  TransactionMetadata,
  'transactionID'
> = {
  overrideCategoryID: null,
  description: null,
};

export type SerializedTransactionMetadata = {
  transactionID: string;
  overrideCategoryID?: string;
  description?: string;
};

export const transactionMetadataSerialization: Serialization<
  TransactionMetadata,
  SerializedTransactionMetadata
> = {
  serialize: txnMetadata => {
    const serialized: SerializedTransactionMetadata = {
      transactionID: txnMetadata.transactionID,
    };

    if (txnMetadata.overrideCategoryID != null) {
      serialized.overrideCategoryID = txnMetadata.overrideCategoryID;
    }

    if (txnMetadata.description != null) {
      serialized.description = txnMetadata.description;
    }

    return serialized;
  },
  deserialize: serialized => {
    return {
      transactionID: serialized.transactionID,
      overrideCategoryID: serialized.overrideCategoryID ?? null,
      description: serialized.description ?? null,
    };
  },
};

/* Util */

export function getMetadataForTransaction(
  transactionID: string,
  metadatas: TransactionMetadata[],
): TransactionMetadata | null {
  for (const metadata of metadatas) {
    if (metadata.transactionID === transactionID) {
      return metadata;
    }
  }
  return null;
}
