import assertNever from 'assert-never';

import {Loan, loanSerialization, SerializedLoan} from '../modeling/loan';
import {
  SerializedSpending,
  Spending,
  spendingSerialization,
} from '../modeling/spending';
import {PlaidTransaction} from '../plaid';
import {Serialization} from '../util/serialization';

import {
  SerializedTransactionFilter,
  TransactionFilter,
  transactionFilterSerialization,
  transactionMatchFilter,
} from './transactionFilter';
import {TransactionMetadata} from './transactionMetadata';

/* Loan */

type PartialLoanCategory = {
  type: 'loan';
  account: string;
  loan: Loan;
};

type SerializedPartialLoanCategory = {
  type: 'loan';
  account: string;
  loan: SerializedLoan;
};

const loanCategorySerialization: Serialization<
  PartialLoanCategory,
  SerializedPartialLoanCategory
> = {
  serialize: loanCategory => {
    return {
      type: 'loan',
      account: loanCategory.account,
      loan: loanSerialization.serialize(loanCategory.loan),
    };
  },
  deserialize: serialized => {
    return {
      type: 'loan',
      account: serialized.account,
      loan: loanSerialization.deserialize(serialized.loan),
    };
  },
};

/* Spending */

type PartialSpendingCategory = {
  type: 'spending';
  spending: Spending;
};

type SerializedPartialSpendingCategory = {
  type: 'spending';
  spending: SerializedSpending;
};

const spendingCategorySerialization: Serialization<
  PartialSpendingCategory,
  SerializedPartialSpendingCategory
> = {
  serialize: spendingCategory => {
    return {
      type: spendingCategory.type,
      spending: spendingSerialization.serialize(spendingCategory.spending),
    };
  },
  deserialize: serialized => {
    return {
      type: serialized.type,
      spending: spendingSerialization.deserialize(serialized.spending),
    };
  },
};

/* Category */

type CategoryCommon = {
  id: string;
  name: string;
  transactionFilter: TransactionFilter | null;
  subCategories: Category[];
};

export type LoanCategory = PartialLoanCategory & CategoryCommon;
export type SpendingCategory = PartialSpendingCategory & CategoryCommon;

export type Category = LoanCategory | SpendingCategory;

type SerializedCategoryCommon = {
  id: string;
  name: string;
  transactionFilter?: SerializedTransactionFilter;
  subCategories?: SerializedCategory[];
};

export type SerializedLoanCategory = SerializedPartialLoanCategory &
  SerializedCategoryCommon;
export type SerializedSpendingCategory = SerializedPartialSpendingCategory &
  SerializedCategoryCommon;

export type SerializedCategory =
  | SerializedLoanCategory
  | SerializedSpendingCategory;

export const categorySerialization: Serialization<
  Category,
  SerializedCategory
> = {
  serialize: category => {
    let partial:
      | SerializedPartialLoanCategory
      | SerializedPartialSpendingCategory;
    if (category.type === 'loan') {
      partial = loanCategorySerialization.serialize(category);
    } else if (category.type === 'spending') {
      partial = spendingCategorySerialization.serialize(category);
    } else {
      assertNever(category);
    }

    const serializedTransactionFilter = category.transactionFilter
      ? transactionFilterSerialization.serialize(category.transactionFilter)
      : null;

    const serializedSubCategories =
      category.subCategories.length > 0
        ? category.subCategories.map(categorySerialization.serialize)
        : null;

    const serialized: SerializedCategory = {
      ...partial,
      id: category.id,
      name: category.name,
    };
    if (serializedTransactionFilter) {
      serialized.transactionFilter = serializedTransactionFilter;
    }
    if (serializedSubCategories) {
      serialized.subCategories = serializedSubCategories;
    }

    return serialized;
  },
  deserialize: serialized => {
    let partial: PartialLoanCategory | PartialSpendingCategory;
    if (serialized.type === 'loan') {
      partial = loanCategorySerialization.deserialize(serialized);
    } else if (serialized.type === 'spending') {
      partial = spendingCategorySerialization.deserialize(serialized);
    } else {
      assertNever(serialized);
    }

    const transactionFilter = serialized.transactionFilter
      ? transactionFilterSerialization.deserialize(serialized.transactionFilter)
      : null;

    const subCategories = (serialized.subCategories || []).map(
      categorySerialization.deserialize,
    );
    return {
      ...partial,
      transactionFilter,
      subCategories,
      id: serialized.id,
      name: serialized.name,
    };
  },
};

/* Util */

export function getCategoryByID(
  categories: Category[],
  categoryID: string,
): Category | null {
  for (const category of categories) {
    if (category.id === categoryID) {
      return category;
    }
    const subCategory = getCategoryByID(category.subCategories, categoryID);
    if (subCategory) {
      return subCategory;
    }
  }
  return null;
}

/* Mutations */

function updateCategoriesImpl(
  category: Category,
  isRootCategory: (category: Category) => boolean,
  update: (
    parentCategory: Category | null,
    originalCategories: Category[],
  ) => Category[],
): Category {
  const originalSubCategories: Category[] = category.subCategories;

  let updatedSubCategories = originalSubCategories.map(c =>
    updateCategoriesImpl(c, isRootCategory, update),
  );
  if (
    updatedSubCategories.every((uc, idx) => uc === originalSubCategories[idx])
  ) {
    // There was no change
    updatedSubCategories = originalSubCategories;
  }
  updatedSubCategories = update(
    isRootCategory(category) ? null : category,
    updatedSubCategories,
  );
  if (updatedSubCategories !== originalSubCategories) {
    return {
      ...category,
      subCategories: updatedSubCategories,
    };
  } else {
    return category;
  }
}

export function updateCategories(
  originalCategories: Category[],
  update: (
    parentCategory: Category | null,
    originalCategories: Category[],
  ) => Category[],
): Category[] {
  // A obj used to make traversal easier
  const rootCategory: Category = {
    type: 'spending',
    spending: {expectedSpending: null},
    id: 'root',
    name: 'root',
    transactionFilter: null,
    subCategories: originalCategories,
  };
  const isRootCategory = (category: Category) => category === rootCategory;

  return updateCategoriesImpl(rootCategory, isRootCategory, update)
    .subCategories;
}

export function updateCategory(
  originalCategories: Category[],
  update: (category: Category) => Category,
): Category[] {
  return updateCategories(originalCategories, (_, categories) => {
    const newCategories = categories.map(update);
    if (newCategories.every((c, idx) => c === categories[idx])) {
      return categories;
    } else {
      return newCategories;
    }
  });
}

/* Narrowing functions */

export function isLoanCategory(category: Category): category is LoanCategory {
  return category.type === 'loan';
}

export function isSpendingCategory(
  category: Category,
): category is SpendingCategory {
  return category.type === 'spending';
}

/* Transaction matching; does not consider sub categories */

export function transactionMatchesAnyCategory(
  transaction: PlaidTransaction,
  transactionMetadata: TransactionMetadata | null,
  categories: Category[],
): boolean {
  return categories.some(
    c =>
      c.id === transactionMetadata?.overrideCategoryID ||
      (c.transactionFilter &&
        transactionMatchFilter(transaction, c.transactionFilter)),
  );
}

export function transactionMatchesCategory(
  transaction: PlaidTransaction,
  transactionMetadata: TransactionMetadata | null,
  matchingCategory: Category,
  ignoringCategories: Category[] = [],
): boolean {
  if (transactionMetadata?.overrideCategoryID === matchingCategory.id) {
    return true;
  }

  if (
    transactionMatchesAnyCategory(
      transaction,
      transactionMetadata,
      ignoringCategories,
    )
  ) {
    return false;
  }

  if (matchingCategory.transactionFilter) {
    return transactionMatchFilter(
      transaction,
      matchingCategory.transactionFilter,
    );
  }
  return false;
}
