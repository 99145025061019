import React, {ReactElement, useContext, useState} from 'react';

import invariant from 'invariant';

import {getCategoryByID} from '../dataMapping/categories';
import {getTransactionsForCategory} from '../dataMapping/getTransactions';
import {getAccountName} from '../rendering/account';
import {cap, element, header, right, useAsKey} from '../rendering/table';

import CategorizeTransactionButton from './CategorizeTransactionButton';
import CategorySelector from './CategorySelector';
import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Table from './Table';
import TransactionDescription from './TransactionDescription';

const MAX_ACCOUNT_LEN = 35;
const MAX_MERCHANT_NAME_LEN = 25;
const MAX_DESC_LEN = 80;

export default function Transactions(): ReactElement {
  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {categories} = fixtureFile.manualData.dataMapping;

  const [selectedCategoryID, setSelectedCategoryID] = useState<string | null>(
    null,
  );

  const selectedCategory =
    selectedCategoryID != null
      ? getCategoryByID(categories, selectedCategoryID)
      : null;

  const txns = getTransactionsForCategory(fixtureFile, selectedCategory);

  txns.sort((txn1, txn2) => -txn1.date.localeCompare(txn2.date));

  const txnRows = txns.map(txn => [
    txn.date,
    getAccountName(fixtureFile.plaidData, null, txn.account_id),
    txn.merchant_name,
    txn.name,
    {
      /* the negative is because positive amount means money moving out of account */
      value: -(txn.amount ?? 0),
      currency: txn.iso_currency_code || 'USD',
    },
    txn.transaction_id.slice(-8),
    ...(selectedCategoryID == null
      ? [element(<CategorizeTransactionButton transaction={txn} />)]
      : []),
    element(<TransactionDescription transaction={txn} />),
  ]);

  const tableConfig = [
    header('Date'),
    cap(header('Account'), MAX_ACCOUNT_LEN),
    cap(header('Merchant'), MAX_MERCHANT_NAME_LEN),
    cap(header('Name'), MAX_DESC_LEN),
    right(header('Amount')),
    useAsKey(header('ID')),
    ...(selectedCategoryID == null ? [header('Categorize')] : []),
    header('Description'),
  ];

  return (
    <div>
      <p>
        <CategorySelector
          categories={categories}
          selectedCategoryID={selectedCategoryID}
          onCategoryChange={setSelectedCategoryID}
        />
        <span style={{marginLeft: 10}}>{txns.length} transactions</span>
      </p>
      <Table rows={txnRows} tableConfig={tableConfig} />
    </div>
  );
}
