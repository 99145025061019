import React, {ReactElement, useContext, useState} from 'react';

import invariant from 'invariant';

import {getCategoryByID, updateCategory} from '../dataMapping/categories';

import FixtureContext, {FixtureContextValue} from './FixtureContext';
import {
  SimpleTransactionFilter,
  simplifyTransactionFilter,
  unsimplifyTransactionFilter,
} from './simpleTransactionFilter';

type Prefill = {
  prependMerchantName?: string;
};

type Props = {
  categoryID: string;
  prefill?: Prefill;
  onClose: () => void;
};

export default function CategoryEditorModal(props: Props): ReactElement {
  const {categoryID, prefill, onClose} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {categories} = currentFixture.fixtureFile.manualData.dataMapping;

  const category = getCategoryByID(categories, categoryID);
  invariant(category, 'Category must exist');

  const simplifiedTransactionFilter = simplifyTransactionFilter(
    category.transactionFilter,
  );
  invariant(
    simplifiedTransactionFilter != null,
    'Should not be here if this is null',
  );

  const [newName, setNewName] = useState<string | null>(null);
  const [newSimplifiedTransactionFilter, setNewSimplifiedTransactionFilter] =
    useState<SimpleTransactionFilter | null>(() => {
      const prependMerchantName = prefill?.prependMerchantName;
      if (prependMerchantName == null) {
        return null;
      } else {
        const merchants = simplifiedTransactionFilter.merchants.slice(0);
        merchants.unshift(prependMerchantName);
        return {merchants};
      }
    });
  const [highlightFirstRow, setHighlightFirstRow] = useState<boolean>(
    prefill?.prependMerchantName != null,
  );

  return (
    <div>
      <p>Category ID: {categoryID}</p>
      <p>
        Category name:{' '}
        <input
          type="text"
          value={newName ?? category.name}
          onChange={e => setNewName(e.target.value)}
        />
      </p>
      <div>
        <p>
          Matching transaction names (if any part of the transaction contains
          the string):
        </p>
        <ul>
          {(
            newSimplifiedTransactionFilter ?? simplifiedTransactionFilter
          ).merchants.map((name, idx) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              style={{
                marginBottom: 5,
              }}>
              <input
                type="text"
                value={name}
                onChange={e => {
                  const merchants = (
                    newSimplifiedTransactionFilter ??
                    simplifiedTransactionFilter
                  ).merchants.slice(0);
                  merchants[idx] = e.target.value;
                  setNewSimplifiedTransactionFilter({merchants});
                }}
                style={{
                  width: 400,
                  ...(highlightFirstRow && idx === 0
                    ? {background: 'yellow'}
                    : {}),
                }}
              />{' '}
              <button
                type="button"
                onClick={() => {
                  const merchants = (
                    newSimplifiedTransactionFilter ??
                    simplifiedTransactionFilter
                  ).merchants.slice(0);
                  merchants.splice(idx, 1);
                  if (idx === 0 && highlightFirstRow) {
                    setHighlightFirstRow(false);
                  }
                  setNewSimplifiedTransactionFilter({merchants});
                }}>
                ✕
              </button>
            </li>
          ))}
          <li>
            <button
              type="button"
              onClick={() => {
                const merchants = (
                  newSimplifiedTransactionFilter ?? simplifiedTransactionFilter
                ).merchants.slice(0);
                merchants.push('Type new value here');
                setNewSimplifiedTransactionFilter({merchants});
              }}>
              Add
            </button>
          </li>
        </ul>
      </div>
      <p>
        <button type="button" onClick={onClose}>
          Cancel
        </button>{' '}
        <button
          type="submit"
          onClick={() => {
            const newCategory = {...category};
            if (newName != null) {
              newCategory.name = newName;
            }
            if (newSimplifiedTransactionFilter) {
              newCategory.transactionFilter = unsimplifyTransactionFilter(
                newSimplifiedTransactionFilter,
              );
            }

            const newRootCategories = updateCategory(categories, c => {
              if (c.id === category.id) {
                return newCategory;
              } else {
                return c;
              }
            });
            const newFixture = {
              ...currentFixture.fixtureFile,
              manualData: {
                ...currentFixture.fixtureFile.manualData,
                dataMapping: {
                  ...currentFixture.fixtureFile.manualData.dataMapping,
                  categories: newRootCategories,
                },
              },
            };

            invariant(
              fixtureContext.status === 'loaded',
              'Fixture state must be loaded',
            );
            fixtureContext.updateFixture(newFixture).then(onClose);
          }}
          disabled={
            (newName == null && newSimplifiedTransactionFilter == null) ||
            fixtureContext.status !== 'loaded'
          }>
          Save
        </button>
      </p>
    </div>
  );
}
