import React, {ReactElement, useContext, useState} from 'react';

import invariant from 'invariant';

import {FixtureFile} from '../data/fixtureFile';
import {Category} from '../dataMapping/categories';
import {
  children,
  element,
  header,
  indent,
  TableRow,
  useAsKey,
} from '../rendering/table';

import CategoryAddModal from './CategoryAddModal';
import CategoryDeleteModal from './CategoryDeleteModal';
import CategoryEditorModal from './CategoryEditorModal';
import CategoryReorderModal from './CategoryReorderModal';
import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Modal from './Modal';
import {simplifyTransactionFilter} from './simpleTransactionFilter';
import Table from './Table';

function getTableRows(
  fixture: FixtureFile,
  createEditButton: (category: Category) => ReactElement,
  createDeleteButton: (category: Category) => ReactElement,
): TableRow[] {
  const recurseCategory = (category: Category | null): TableRow[] => {
    const childrenRows = (category?.subCategories || [])
      .map(recurseCategory)
      .flat(1);
    return [
      [
        category?.name ?? 'Uncategorized',
        category ? element(createEditButton(category)) : null,
        category ? element(createDeleteButton(category)) : null,
        category ? category.id : 'uncategorized',
      ],
      ...(childrenRows.length > 0 ? [children(childrenRows)] : []),
    ];
  };
  return [
    ...recurseCategory(null),
    ...fixture.manualData.dataMapping.categories.map(recurseCategory).flat(1),
  ];
}

const tableConfig = [
  indent(header('Category')),
  header('Edit'),
  header('Delete'),
  useAsKey(header('Category ID')),
];

export default function Categories(): ReactElement {
  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;

  const [showAddCategory, setShowAddCategory] = useState<boolean>(false);
  const [showReorderCategories, setShowReorderCategories] =
    useState<boolean>(false);
  const [editCategoryID, setEditCategoryID] = useState<string | null>(null);
  const [deleteCategoryID, setDeleteCategoryID] = useState<string | null>(null);

  const createEditButton = (category: Category) => {
    const simplifiedTransactionFilter = simplifyTransactionFilter(
      category.transactionFilter,
    );
    return (
      <button
        type="button"
        onClick={() => setEditCategoryID(category.id)}
        disabled={simplifiedTransactionFilter == null}
        title={
          simplifiedTransactionFilter == null
            ? 'Transaction filter too complicated to edit'
            : undefined
        }>
        Edit
      </button>
    );
  };

  const createDeleteButton = (category: Category) => {
    return (
      <button type="button" onClick={() => setDeleteCategoryID(category.id)}>
        Delete
      </button>
    );
  };

  return (
    <>
      <p>
        The order of categories matters. The first category to match a
        transaction is what is applied even if later categories may match.
      </p>
      <p>
        <button type="button" onClick={() => setShowAddCategory(true)}>
          Add new category
        </button>{' '}
        <button type="button" onClick={() => setShowReorderCategories(true)}>
          Reorder categories
        </button>
      </p>
      <Table
        rows={getTableRows(
          currentFixture.fixtureFile,
          createEditButton,
          createDeleteButton,
        )}
        tableConfig={tableConfig}
      />
      {showAddCategory ? (
        <Modal onClose={() => setShowAddCategory(false)}>
          <CategoryAddModal onClose={() => setShowAddCategory(false)} />
        </Modal>
      ) : null}
      {showReorderCategories ? (
        <Modal onClose={() => setShowReorderCategories(false)}>
          <CategoryReorderModal
            onClose={() => setShowReorderCategories(false)}
          />
        </Modal>
      ) : null}
      {editCategoryID != null ? (
        <Modal onClose={() => setEditCategoryID(null)}>
          <CategoryEditorModal
            categoryID={editCategoryID}
            onClose={() => setEditCategoryID(null)}
          />
        </Modal>
      ) : null}
      {deleteCategoryID != null ? (
        <Modal onClose={() => setDeleteCategoryID(null)}>
          <CategoryDeleteModal
            categoryID={deleteCategoryID}
            onClose={() => setDeleteCategoryID(null)}
          />
        </Modal>
      ) : null}
    </>
  );
}
