import {createAsIsSerialization, Serialization} from '../util/serialization';

import {ExpectedAmount} from './expectedAmount';
import {Frequency} from './frequency';

export type ExpectatedSpending = {
  expectedAmount: ExpectedAmount;
  frequency: Frequency;
};

export type SerializedExpectatedSpending = ExpectatedSpending;

export const expectedSpendingSerialization: Serialization<
  ExpectatedSpending,
  SerializedExpectatedSpending
> = createAsIsSerialization();

export type Spending = {
  expectedSpending: ExpectatedSpending | null;
};

export type SerializedSpending = {
  expectedSpending?: SerializedExpectatedSpending;
};

export const spendingSerialization: Serialization<
  Spending,
  SerializedSpending
> = {
  serialize: spending => {
    const serialized: SerializedSpending = {};
    const serializedExpectedSpending = spending.expectedSpending
      ? expectedSpendingSerialization.serialize(spending.expectedSpending)
      : null;
    if (serializedExpectedSpending) {
      serialized.expectedSpending = serializedExpectedSpending;
    }
    return serialized;
  },
  deserialize: serialized => {
    return {
      expectedSpending: serialized.expectedSpending
        ? expectedSpendingSerialization.deserialize(serialized.expectedSpending)
        : null,
    };
  },
};
