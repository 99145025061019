export type Serialization<Type, SerializedType> = {
  serialize(obj: Type): SerializedType;
  deserialize(serialized: SerializedType): Type;
};

export function createAsIsSerialization<Type>(): Serialization<Type, Type> {
  return {
    serialize: obj => obj,
    deserialize: obj => obj,
  };
}

export const stringListSerialization: Serialization<string[], string[]> =
  createAsIsSerialization<string[]>();

export function createListSerialization<ItemType, SerializedItemType>(
  itemSerialization: Serialization<ItemType, SerializedItemType>,
): Serialization<ItemType[], SerializedItemType[]> {
  return {
    serialize: list => list.map(itemSerialization.serialize),
    deserialize: serialized => serialized.map(itemSerialization.deserialize),
  };
}

export function createSetSerialization<ItemType, SerializedItemType>(
  itemSerialization: Serialization<ItemType, SerializedItemType>,
): Serialization<Set<ItemType>, SerializedItemType[]> {
  return {
    serialize: set => Array.from(set).map(itemSerialization.serialize),
    deserialize: array =>
      new Set<ItemType>(array.map(itemSerialization.deserialize)),
  };
}

export const stringSetSerialization: Serialization<
  Set<string>,
  string[]
> = createSetSerialization<string, string>(createAsIsSerialization());

export function convertToNullArraySerialization<Type, SerializedType>(
  serialization: Serialization<Type, SerializedType[]>,
): Serialization<Type, SerializedType[] | null> {
  return {
    serialize: obj => {
      const serialized = serialization.serialize(obj);
      return serialized.length === 0 ? null : serialized;
    },
    deserialize: serialized => serialization.deserialize(serialized || []),
  };
}

export const stringNullListSerialization: Serialization<
  string[],
  string[] | null
> = convertToNullArraySerialization(stringListSerialization);

export const stringNullSetSerialization: Serialization<
  Set<string>,
  string[] | null
> = convertToNullArraySerialization(stringSetSerialization);
