import invariant from 'invariant';

import Day from './Day';

export default class Month {
  readonly date: Date;

  constructor(public readonly year: number, public readonly month: number) {
    this.date = new Date(year, month - 1);
  }

  // YYYY-MM
  serialize(): string {
    return [this.year, this.month]
      .map(n => n.toString().padStart(2, '0'))
      .join('-');
  }

  equals(other: Month): boolean {
    return this.compare(other) === 0;
  }

  compare(other: Month): number {
    return this.valueOf() - other.valueOf();
  }

  valueOf(): number {
    return this.date.valueOf();
  }

  getPreceeding(): Month {
    return this.getNthSucceeding(-1);
  }

  getSucceeding(): Month {
    return this.getNthSucceeding(1);
  }

  getNthSucceeding(n: number): Month {
    const date = new Date(this.year, this.month - 1 + n);
    return new Month(date.getFullYear(), date.getMonth() + 1);
  }

  getNumberOfDays(): number {
    return new Date(this.year, this.month, 0).getDate();
  }

  getNthDayOfMonth(day: number): Day {
    invariant(
      day <= this.getNumberOfDays(),
      `There is no day ${day} of ${this.serialize()}`,
    );
    return new Day(this.year, this.month, day);
  }

  static currentMonth(): Month {
    const now = new Date();
    return new Month(now.getFullYear(), now.getMonth() + 1);
  }

  // Refactor to its own class
  static currentQuarterStartMonth(): Month {
    const currentMonth = Month.currentMonth();
    return new Month(
      currentMonth.year,
      currentMonth.month - ((currentMonth.month - 1) % 3),
    );
  }

  // Refactor to its own class
  static currentYearStartMonth(): Month {
    const currentMonth = Month.currentMonth();
    return new Month(currentMonth.year, 1);
  }

  static deserialize(serialized: string): Month {
    const result = Month._deserializeImpl(serialized);
    if (result instanceof Error) {
      throw result;
    }
    return result;
  }

  static maybeDeserialize(serialized: string): Month | null {
    const result = Month._deserializeImpl(serialized);
    if (result instanceof Month) {
      return result;
    }
    return null;
  }

  static _deserializeImpl(serialized: string): Month | Error {
    const split = serialized.split('-');
    if (split.length !== 2) {
      return new Error(`Month (${serialized}) does not have a dash (-)`);
    }
    const parsed = split.map(s => parseInt(s, 10));
    if (parsed.some(p => Number.isNaN(p))) {
      return new Error(
        `Serialized Month (${serialized}) does not have numbers`,
      );
    }
    const [year, month] = parsed;
    return new Month(year, month);
  }

  static truncateDay(day: Day): Month {
    return new Month(day.year, day.month);
  }
}
