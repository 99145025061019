import {Serialization} from '../util/serialization';

export default class Day {
  date: Date;

  constructor(
    public year: number,
    public month: number /* 1 based */,
    public day: number,
  ) {
    this.date = new Date(year, month - 1, day);
  }

  // YYYY-MM-DD
  serialize(): string {
    return [this.year, this.month, this.day]
      .map(n => n.toString().padStart(2, '0'))
      .join('-');
  }

  equals(other: Day): boolean {
    return this.compare(other) === 0;
  }

  compare(other: Day): number {
    return this.valueOf() - other.valueOf();
  }

  valueOf(): number {
    return this.date.valueOf();
  }

  getPreceeding(): Day {
    return this.getNthSucceeding(-1);
  }

  getSucceeding(): Day {
    return this.getNthSucceeding(1);
  }

  getNthSucceeding(n: number): Day {
    const date = new Date(this.year, this.month - 1, this.day + n);
    return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  static today(): Day {
    const now = new Date();
    return new Day(now.getFullYear(), now.getMonth() + 1, now.getDate());
  }

  static epoch: Day = new Day(1970, 1, 1);

  static deserialize(serialized: string): Day {
    const result = Day.deserializeImpl(serialized);
    if (result instanceof Error) {
      throw result;
    }
    return result;
  }

  static maybeDeserialize(serialized: string): Day | null {
    const result = Day.deserializeImpl(serialized);
    if (result instanceof Day) {
      return result;
    }
    return null;
  }

  private static deserializeImpl(serialized: string): Day | Error {
    const split = serialized.split('-');
    if (split.length !== 3) {
      return new Error(`Day (${serialized}) does not have two dashes (-)`);
    }
    const parsed = split.map(s => parseInt(s, 10));
    if (parsed.some(p => Number.isNaN(p))) {
      return new Error(`Serialized Day (${serialized}) does not have numbers`);
    }
    const [year, month, day] = parsed;
    return new Day(year, month, day);
  }
}

export type SerializedDay = string;

export const daySerialization: Serialization<Day, SerializedDay> = {
  serialize: day => day.serialize(),
  deserialize: serialized => Day.deserialize(serialized),
};
