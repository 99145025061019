import {getAccounts} from '../data/accounts';
import {FixtureFile} from '../data/fixtureFile';
import {ManualAccount} from '../data/manualAccount';
import type {PlaidAccount, PlaidTransaction} from '../plaid';

import {getAccountName} from './account';
import {getAccountTypeName} from './accountTypes';
import {
  children,
  header,
  indent,
  right,
  sum,
  TableConfig,
  TableRow,
  useAsKey,
} from './table';

function getEarliestTransaction(
  fixture: FixtureFile,
  accountID: string,
): PlaidTransaction | null {
  let earliest: PlaidTransaction | null = null;
  fixture.plaidData.transactions.forEach(txn => {
    if (txn.account_id !== accountID) {
      return;
    }
    if (!earliest) {
      earliest = txn;
    }
    if (txn.date.localeCompare(earliest.date) < 0) {
      earliest = txn;
    }
  });
  return earliest;
}

function getLatestTransaction(
  fixture: FixtureFile,
  accountID: string,
): PlaidTransaction | null {
  let latest: PlaidTransaction | null = null;
  fixture.plaidData.transactions.forEach(txn => {
    if (txn.account_id !== accountID) {
      return;
    }
    if (!latest) {
      latest = txn;
    }
    if (txn.date.localeCompare(latest.date) > 0) {
      latest = txn;
    }
  });
  return latest;
}

export default function getBalancesForTable(fixtureFile: FixtureFile): {
  rows: TableRow[];
  tableConfig: TableConfig;
} {
  const {plaidData, manualData} = fixtureFile;

  // Group accounts by type and then sub_type
  const accounts: (PlaidAccount | ManualAccount)[] = getAccounts(fixtureFile);

  accounts.sort((acct1, acct2) => {
    const {typeIdx: typeIdx1, subTypeIdx: subTypeIdx1} =
      getAccountTypeName(acct1);
    const {typeIdx: typeIdx2, subTypeIdx: subTypeIdx2} =
      getAccountTypeName(acct2);
    if (typeIdx1 !== typeIdx2) {
      return typeIdx1 - typeIdx2;
    } else if (subTypeIdx1 !== subTypeIdx2) {
      return subTypeIdx1 - subTypeIdx2;
    } else {
      return getAccountName(
        plaidData,
        manualData,
        acct1.account_id,
      ).localeCompare(getAccountName(plaidData, manualData, acct2.account_id));
    }
  });

  const accountTypes: {
    type: string;
    name: string;
    subTypes: {subType: string; name: string; negateBalance: boolean}[];
  }[] = [];
  accounts.forEach(account => {
    const {typeName, subTypeName, negateBalance} = getAccountTypeName(account);
    const prevAccountType =
      accountTypes.length === 0 ? null : accountTypes[accountTypes.length - 1];
    if (!prevAccountType || prevAccountType.type !== account.type) {
      accountTypes.push({
        type: account.type,
        name: typeName,
        subTypes: [
          {
            subType: account.subtype,
            name: subTypeName,
            negateBalance,
          },
        ],
      });
    } else if (
      prevAccountType.subTypes[prevAccountType.subTypes.length - 1].subType !==
      account.subtype
    ) {
      prevAccountType.subTypes.push({
        subType: account.subtype,
        name: subTypeName,
        negateBalance,
      });
    }
  });

  return {
    rows: [
      ...accountTypes
        .map(accountType => [
          [accountType.name],
          children(
            accountType.subTypes
              .map(subType => [
                [subType.name],
                children(
                  accounts
                    .filter(
                      account =>
                        account.type === accountType.type &&
                        account.subtype === subType.subType,
                    )
                    .map(account => [
                      getAccountName(plaidData, manualData, account.account_id),
                      {
                        value:
                          account.balances.current *
                          (subType.negateBalance ? -1 : 1),
                        currency: account.balances.iso_currency_code || 'USD',
                      },
                      account.account_id.slice(-5),
                      getLatestTransaction(fixtureFile, account.account_id)
                        ?.date || '',
                      getEarliestTransaction(fixtureFile, account.account_id)
                        ?.date || '',
                    ]),
                ),
              ])
              .flat(1),
          ),
        ])
        .flat(1),
      [],
      ['Total', sum()],
    ],
    tableConfig: [
      useAsKey(indent(header('Account'))),
      right(header('Balance')),
      useAsKey(header('ID')),
      header('Latest Txn Date'),
      header('Earliest Txn Date'),
    ],
  };
}
