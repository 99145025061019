import {Serialization, stringNullSetSerialization} from '../util/serialization';

export type Diff<T> = {
  newIDs: Set<string>;
  replacedObjects: T[];
  oldIDs: Set<string>; // not in the new data
  // If not in here, assumed to be unchanged
};

export function createEmptyDiff<T>(): Diff<T> {
  return {
    newIDs: new Set(),
    replacedObjects: [],
    oldIDs: new Set(),
  };
}

export type SerializedDiff<T> = {
  newIDs?: string[];
  replacedObjects?: T[];
  oldIDs?: string[];
};

export function createDiffSerialization<Type, SerializedType>(
  objSerialization: Serialization<Type, SerializedType>,
): Serialization<Diff<Type>, SerializedDiff<SerializedType> | null> {
  return {
    serialize: (diff: Diff<Type>): SerializedDiff<SerializedType> | null => {
      let serialized: SerializedDiff<SerializedType> | null = null;

      const newIDs = stringNullSetSerialization.serialize(diff.newIDs);
      if (newIDs) {
        serialized = serialized || {};
        serialized.newIDs = newIDs;
      }

      const oldIDs = stringNullSetSerialization.serialize(diff.oldIDs);
      if (oldIDs) {
        serialized = serialized || {};
        serialized.oldIDs = oldIDs;
      }

      if (diff.replacedObjects.length > 0) {
        serialized = serialized || {};
        serialized.replacedObjects = diff.replacedObjects.map(
          objSerialization.serialize,
        );
      }

      return serialized;
    },
    deserialize: serialized => {
      if (!serialized) {
        return createEmptyDiff();
      } else {
        return {
          newIDs: stringNullSetSerialization.deserialize(
            serialized.newIDs || null,
          ),
          replacedObjects: (serialized.replacedObjects || []).map(
            objSerialization.deserialize,
          ),
          oldIDs: stringNullSetSerialization.deserialize(
            serialized.oldIDs || null,
          ),
        };
      }
    },
  };
}
