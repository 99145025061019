import {FixtureFile} from '../data/fixtureFile';

const FILE_PREFIX = 'fixtures-';
const VERSION_PAD_LENGTH = 5;
const EXTENSION = 'json';
const FILENAME_REGEX = /^fixtures-([\d]{5})\.json$/;

export const FIXTURE_VERSION_FILENAME = 'fixtureVersion.json';

export function getFixtureFilename(version: number): string {
  return `${FILE_PREFIX}${version
    .toString()
    .padStart(VERSION_PAD_LENGTH, '0')}.${EXTENSION}`;
}

export function getVersionFromFilename(filename: string): number | null {
  const match = filename.match(FILENAME_REGEX);
  if (!match) {
    return null;
  }
  return parseInt(match[1], 10);
}

export type FixtureFileSystem = {
  hasFixtureForVersion(version: number): Promise<boolean>;

  getCurrentFixtureVersion(): Promise<number>;

  getCurrentFixture(): Promise<{fixtureFile: FixtureFile; version: number}>;

  // AsText so that data can be copied without (de)serialization differences across versions.
  getFixtureFileAsText(version: number): Promise<string>;

  writeFixtureFileAsText(fixtureFile: string, version: number): Promise<void>;

  setVersion(version: number): Promise<void>;

  // Returns updated version
  updateFixtureFile(fixtureFile: FixtureFile, version: number): Promise<number>;
};
