import React, {ReactElement, useContext} from 'react';

import invariant from 'invariant';

import getCashflowForTable from '../rendering/getCashflowForTable';

import FixtureContext, {FixtureContextValue} from './FixtureContext';
import Table from './Table';

export default function Cashflow(): ReactElement {
  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;

  const {rows, tableConfig} = getCashflowForTable(currentFixture.fixtureFile);

  return <Table rows={rows} tableConfig={tableConfig} />;
}
