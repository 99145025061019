import React, {ReactElement} from 'react';

import FixtureContextProvider from './FixtureContextProvider';
import ModalContext from './ModalContext';
import {Page} from './Page';

export type Props = {
  modalRoot: HTMLElement;
};

export function App(props: Props): ReactElement {
  const {modalRoot} = props;
  return (
    <ModalContext.Provider value={{modalRoot}}>
      <FixtureContextProvider>
        <Page />
      </FixtureContextProvider>
    </ModalContext.Provider>
  );
}
