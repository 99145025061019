import type {PlaidTransaction} from '../plaid';
import {Amount} from '../util/amount';

export default function sumTransactionAmounts(
  txns: PlaidTransaction[],
  currency: string,
): Amount | null {
  if (txns.length === 0) {
    return null;
  }
  const total = txns.map(txn => txn.amount ?? 0).reduce((acc, n) => acc + n, 0);
  return {
    // -0 is not the same as 0.
    value: total === 0 ? total : -total,
    currency,
  };
}
