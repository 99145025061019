import {
  convertToNullArraySerialization,
  createListSerialization,
  Serialization,
} from '../util/serialization';

import {
  Category,
  categorySerialization,
  SerializedCategory,
} from './categories';
import {
  SerializedTransactionMetadata,
  TransactionMetadata,
  transactionMetadataSerialization,
} from './transactionMetadata';

export type DataMapping = {
  categories: Category[];
  transactionsMetadata: TransactionMetadata[];
};

export const emptyDataMapping: DataMapping = {
  categories: [],
  transactionsMetadata: [],
};

export type SerializedDataMapping = {
  categories?: SerializedCategory[];
  transactionsMetadata?: SerializedTransactionMetadata[];
};

const categoryListSerialization: Serialization<
  Category[],
  SerializedCategory[] | null
> = convertToNullArraySerialization(
  createListSerialization(categorySerialization),
);

const transactionMetadataListSerialization: Serialization<
  TransactionMetadata[],
  SerializedTransactionMetadata[] | null
> = convertToNullArraySerialization(
  createListSerialization(transactionMetadataSerialization),
);

export const dataMappingSerialization: Serialization<
  DataMapping,
  SerializedDataMapping | null
> = {
  serialize: dataMapping => {
    let serialized: SerializedDataMapping | null = null;

    const serializedCategories = categoryListSerialization.serialize(
      dataMapping.categories,
    );
    if (serializedCategories) {
      serialized = serialized || {};
      serialized.categories = serializedCategories;
    }

    const serializedTransactionMetadata =
      transactionMetadataListSerialization.serialize(
        dataMapping.transactionsMetadata,
      );
    if (serializedTransactionMetadata) {
      serialized = serialized || {};
      serialized.transactionsMetadata = serializedTransactionMetadata;
    }

    return serialized;
  },
  deserialize: serialized => {
    if (!serialized) {
      return emptyDataMapping;
    }
    return {
      categories: categoryListSerialization.deserialize(
        serialized.categories || null,
      ),
      transactionsMetadata: transactionMetadataListSerialization.deserialize(
        serialized.transactionsMetadata || null,
      ),
    };
  },
};
