import assertNever from 'assert-never';

import {createAsIsSerialization, Serialization} from '../util/serialization';

export type Monthly = {
  frequency: 'monthly';
  nthOfMonth: number;
};

export type Frequency = Monthly;

export type SerializedFrequency = Frequency;

export const frequencySerialization: Serialization<
  Frequency,
  SerializedFrequency
> = createAsIsSerialization<Frequency>();

export function frequencyToString(frequency: Frequency): string {
  if (frequency.frequency === 'monthly') {
    const suffixes = [null, 'st', 'nd', 'rd'];
    return `every month on the ${frequency.nthOfMonth}${
      suffixes[frequency.nthOfMonth] || 'th'
    }`;
  }
  assertNever(frequency.frequency);
}
