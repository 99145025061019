import React, {ReactElement, useMemo, useState, useContext} from 'react';

import invariant from 'invariant';

import {
  Category,
  getCategoryByID,
  updateCategories,
} from '../dataMapping/categories';

import CategorySelector from './CategorySelector';
import FixtureContext, {FixtureContextValue} from './FixtureContext';

type Props = {
  onClose: () => void;
};

export default function CategoryAddModal(props: Props): ReactElement {
  const {onClose} = props;

  const fixtureContext = useContext<FixtureContextValue>(FixtureContext);
  invariant(
    fixtureContext.status === 'loaded' || fixtureContext.status === 'updating',
    'Must already be loaded or updating',
  );
  const {currentFixture} = fixtureContext;
  const {fixtureFile} = currentFixture;
  const {categories} = fixtureFile.manualData.dataMapping;

  const [categoryID, setCategoryID] = useState<string>('');
  const [categoryName, setCategoryName] = useState<string>('');
  const [parentCategoryID, setParentCategoryID] = useState<string | null>(null);

  const isCategoryIDUnique = useMemo<boolean>(() => {
    return getCategoryByID(categories, categoryID) == null;
  }, [categoryID, categories]);

  const onAdd = () => {
    const newCategory: Category = {
      id: categoryID,
      name: categoryName,
      type: 'spending',
      spending: {
        expectedSpending: null,
      },
      transactionFilter: null,
      subCategories: [],
    };

    const newCategories = updateCategories(
      categories,
      (parentCategory, originalCategories) => {
        const pcID = parentCategory ? parentCategory.id : null;
        if (pcID === parentCategoryID) {
          const updatedCategories = originalCategories.slice(0);
          updatedCategories.push(newCategory);
          return updatedCategories;
        } else {
          return originalCategories;
        }
      },
    );

    const newFixture = {
      ...fixtureFile,
      manualData: {
        ...fixtureFile.manualData,
        dataMapping: {
          ...fixtureFile.manualData.dataMapping,
          categories: newCategories,
        },
      },
    };

    invariant(
      fixtureContext.status === 'loaded',
      'Fixture state must be loaded',
    );
    fixtureContext.updateFixture(newFixture).then(onClose);
  };

  return (
    <div style={{minWidth: 500}}>
      <p>
        Category ID:{' '}
        <input
          type="text"
          value={categoryID}
          onChange={e => setCategoryID(e.target.value)}
          placeholder="e.g. house_mortgage"
        />
        <br />
        This cannot be changed once created.
        {isCategoryIDUnique ? null : (
          <>
            <br />
            This category ID is already used by another category. Choose another
            one.
          </>
        )}
      </p>
      <p>
        Category name:{' '}
        <input
          type="text"
          value={categoryName}
          onChange={e => setCategoryName(e.target.value)}
        />
      </p>
      <p>
        Parent category:{' '}
        <CategorySelector
          categories={categories}
          selectedCategoryID={parentCategoryID}
          onCategoryChange={setParentCategoryID}
          noCategoryName="No parent category"
        />
      </p>
      <button
        type="button"
        onClick={onClose}
        disabled={fixtureContext.status !== 'loaded'}>
        Cancel
      </button>{' '}
      <button
        type="button"
        onClick={onAdd}
        disabled={
          fixtureContext.status !== 'loaded' ||
          !categoryID ||
          !categoryName ||
          !isCategoryIDUnique
        }>
        Add category
      </button>
    </div>
  );
}
